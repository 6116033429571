import { toast } from 'react-toastify';
import { getMedicalRecordID } from 'reducers/medicalRecordReducer';
function useCreateRecordResponse({
  isSuccess = false,
  isFailed = false,
  trigger,
  setTrigger,
  setIsEdit,
  // setShowScreen,
  isFetchViewSuccess,
  patientID,
  dispatch,
}) {
  if (isSuccess && trigger.trigger) {
    toast.success('Record is Created/Updated');
    setIsEdit(false);
    // setShowScreen(0);
    setTrigger({ ...trigger, trigger: false });
  }
  if (isFailed && trigger.trigger) {
    toast.error('Record is not Created');
    setIsEdit(false);
    setTrigger({ ...trigger, trigger: false });
  }
  if (isFetchViewSuccess && trigger.patientID) {
    const recordID = patientID[0].id;
    setTrigger({ ...trigger, patientID: false, recordID: true });
    dispatch(getMedicalRecordID(recordID));
  }
}

export default useCreateRecordResponse;
