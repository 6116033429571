/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Accordion,
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Form,
  FormControl,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

// import components below
import ConditionalRender from 'components/ConditionalRender';
import { selectActivePatients } from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import { createMedicoLegal, getMedicalFormsbyID } from 'reducers/thunks/medicalRecordThunks';

// import assets below
import 'react-bootstrap-typeahead/css/Typeahead.css';

// main component
const MedicoLegalCertificate = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();
  const [isAdd, setIsAdd] = useState(false);
  const logs = useSelector(selectActivePatients);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });

  const [formData, setFormData] = useState({
    address: '',
    age: '',
    date: '',
    days: '',
    full_name: '',
    time: '',
    sustained_by: '',
    injuries: '',
    purpose: '',
    attending_physician: '',
    name_of_person_requesting: '',
    license_no: '',
    type: 'medico_legal_cert',
  });
  const [validated, setValidated] = useState(false);
  const isEditing = isEdit === true;
  const isAdding = isAdd === true;
  const isNotEditing = isEdit === false;

  // For Edit

  const fullChange = (key) => (e) => {
    setFormData({
      ...formData,
      ['full_name']: e[0].name,
    });

    setFormData({
      ...formData,
      ['patientId']: e[0].id,
    });
  };

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };

  useEffect(() => {
    dispatch(getPatients());

    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
    } else {
      setFormData(viewForm);
    }
  }, [viewForm, isEdit]);

  const checkKey = (key) => {
    if (key === 'age') {
      return 'number';
    } else if (key === 'confined_start_date' || key === 'confined_end_date') {
      return 'date';
    } else {
      return 'text';
    }
  };

  const textBox = (key) => {
    return (
      <FormControl
        type={`${checkKey(key)}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`ms-1 ${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };
  // For Add
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createMedicoLegal({ ...formData, method: 'post' }));
    }
    // toast.warning('Fill up the  fields');
    setValidated(true);
  };
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createMedicoLegal({
        ...formData,
        method: 'put',
        type: 'medico_legal_cert',
      }),
    );

    setIsEdit(false);
    return;
  };
  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);
  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
    } = logs;

    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_medico_legal_cert;
      setFormData(data);
    }
  }, [logs]);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className='mb-4'>
        <Col className='first-row'>
          <Form.Group className='input-group'>
            <FormControl
              placeholder='Enter Here'
              value={formData?.name_of_hospital}
              onChange={handleChange('name_of_hospital')}
              className='text-center'
            />
            <Form.Label>Name of Healthcare Facility</Form.Label>
          </Form.Group>

          <Form.Group className='input-group'>
            <FormControl
              placeholder='Enter Here'
              value={formData?.address}
              onChange={handleChange('address')}
              className='text-center'
            />
            <Form.Label>Address</Form.Label>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className='second-row'>
          <Form.Group className='input-group'>
            <Form.Label>Certificate No.</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.cert_no}
              onChange={handleChange('cert_no')}
            />
          </Form.Group>

          <Form.Group className='input-group'>
            <Form.Label>Health Record No.</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.health_record_no}
              onChange={handleChange('health_record_no')}
            />
          </Form.Group>

          <Form.Group className='input-group'>
            <Form.Label>Date</Form.Label>
            <FormControl type='date' value={formData?.date} onChange={handleChange('date')} />
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className='third-row'>
          <h2>{viewForm?.type}</h2>
        </Col>
      </Row>

      <Row className='mb-2'>
        <p className='mb-3'>To Whom It May Concern:</p>

        <p className='mb-3'>
          This is to certify that
          {textBox('full_name')}, {textBox('age')} years old male / female, single / married /
          widow, Filipino, and a resident of {textBox('address')} at about {textBox('time')} for the
          following {textBox('injuries')}
        </p>

        <p className='mb-3'>sustained by {textBox('sustained_by')}</p>

        <p className='mb-3'>
          In my opinion, the injury / injuries sustained by the patient will incapacitate or require
          medical attention for a period of {textBox('days')} days barring complications, otherwise
          the period of healing will vary accordingly.
        </p>

        <p className='mb-5'>
          This certification is being issued at the request of{' '}
          <div className={`request-line-bottom ${isEdit ? 'editable' : 'non-editable'}`}>
            {textBox('name_of_person_requesting')}
          </div>{' '}
          for{' '}
          <div className={`purpose-line-bottom ${isEdit ? 'editable' : 'non-editable'}`}>
            {textBox('purpose')}
          </div>
        </p>

        <div className='signature mb-4'>
          <Form.Group className='attending-physician'>
            <FormControl
              value={formData?.attending_physician}
              onChange={handleChange('attending_physician')}
              className='text-center'
            ></FormControl>
            <Form.Label>Attending Physician</Form.Label>
          </Form.Group>

          <Form.Group className='license-number'>
            <Form.Label>License No.</Form.Label>
            <FormControl
              value={formData?.license_no}
              onChange={handleChange('license_no')}
              placeholder='Enter Here'
            ></FormControl>
          </Form.Group>
        </div>

        <p className='seal pb-4'>( NOT VALID WITHOUT SEAL )</p>
      </Row>

      <Row className=''>
        <Col className='form-buttons'>
          {/* Buttons to show for Add */}
          <ConditionalRender
            condition={isAdding}
            renderIf={
              <>
                <Button type='submit' className='save' disabled={trigger.trigger}>
                  CREATE RECORD
                </Button>
                <Button
                  onClick={() => {
                    setIsEdit(false);
                    setShowScreen(0);
                  }}
                  className='cancel'
                >
                  CANCEL
                </Button>
              </>
            }
          />

          {/* Buttons to show for Edit/View */}
          <ConditionalRender
            condition={!isAdding}
            renderIf={
              <>
                <ConditionalRender
                  condition={isNotEditing}
                  renderIf={
                    <>
                      <Button className='print'>PRINT CERTIFICATE</Button>
                      <Button
                        onClick={() => {
                          setIsEdit(true);
                        }}
                        className='edit'
                      >
                        EDIT CERTIFICATE
                      </Button>
                    </>
                  }
                />

                <ConditionalRender
                  condition={isEditing}
                  renderIf={
                    <>
                      <Button
                        onClick={() => {
                          saveChanges();
                        }}
                        disabled={trigger.trigger}
                        className='save'
                      >
                        SAVE CHANGES
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEdit(false);
                        }}
                        className='cancel'
                      >
                        CANCEL
                      </Button>
                    </>
                  }
                />
              </>
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

export default MedicoLegalCertificate;
