/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Form, FormControl } from 'react-bootstrap';

// import components below
import PrintContent from './PrintComponents/AdmissionAndDischarge';
import PatientSelection from './PatientSelection';
import ConditionalRender from 'components/ConditionalRender';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import {
  getMedicalFormsbyID,
  selectActivePatients,
  createAdmissionandDischargeRecord,
} from 'reducers/medicalRecordReducer';
import './AdmissionAndDischarge.scss';

// main component
const AdmisionAndDischarge = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();

  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [isAdd, setIsAdd] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [validated, setValidated] = useState(false);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [formData, setFormData] = useState({
    // id: '',
    name_of_healthcare_facility: '',
    healthcare_facility_address: '',
    hrn: '',
    patient_lname: '',
    patient_mname: '',
    patient_fname: '',
    ward_room_bed_service: '',
    permanent_address: '',
    tel_no: '',
    sex: '',
    civil_status: '',
    date_of_birth: '',
    age: '',
    birth_place: '',
    nationality: '',
    religion: '',
    occupation: '',
    employer_type_of_business: '',
    employer_address: '',
    employer_contact_no: '',
    father_name: '',
    father_address: '',
    father_contact: '',
    mother_name: '',
    mother_address: '',
    mother_contact: '',
    spouse_name: '',
    spouse_address: '',
    spouse_contact: '',
    admission_date: '',
    admission_time: '',
    discharge_date: '',
    discharge_time: '',
    total_no_days: '',
    admitting_physician: '',
    admitting_clerk: '',
    attending_physician: '',
    type_of_admission: '',
    reffered_by: '',
    social_service_classification: '',
    allergic_to: '',
    hospitalization_plan: '',
    health_insurance_name: '',
    phic: '',
    data_furnished_by: '',
    address_of_informat: '',
    relation_to_patient: '',
    admission_diagnosis: '',
    discharge_diagnosis: '',
    icd_ruv_code: '',
    other_diagnosis: '',
    principal_operation_procedure: '',
    principal_operation_procedure_icd_ruv_code: '',
    accident_injuries_poisoning: '',
    disposition: '',
    result: '',
    is_died: '',
    is_died_less_than_48_hours: '',
    is_died_greater_than_48_hours: '',
    is_autopsy: '',
    is_no: '',
    patientId: Math.random(),
    type: 'admission_and_discharge',
  });

  const isAdding = isAdd === true;
  const isEditing = isEdit === true;
  const isNotEditing = isEdit === false;

  // For Add
  const addRecord = () => {
    return;
  };

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return { ...prev, [key]: e.target.value };
    });

    return;
  }; // For Add

  // add
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createAdmissionandDischargeRecord({ ...formData, method: 'post' }));
      // toast.success('Successfully created record.');
      setIsAdd(false);
      return;
    }

    toast.warning('Fill up the required fields');
    setValidated(true);
  };

  // For Edit
  const saveChanges = async () => {
    const res = await dispatch(
      createAdmissionandDischargeRecord({
        ...formData,
        method: 'put',
        type: 'admission_and_discharge',
      }),
    );

    if (res.payload.status === 'Success') {
      toast.success('Record is Created/Updated');
      setIsEdit(false);
      setDefaultData(formData);

      return;
    }

    toast.error('Record is not Created/Updated');
  };

  const textBox = (key) => {
    return (
      <FormControl
        type={`${key === 'age' ? 'number' : 'text'}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };

  const checkChange = (key) => (e) => {
    if (formData[key]) {
      setFormData({ ...formData, [key]: '' });
    }
    if (!formData[key]) {
      setFormData({ ...formData, [key]: e.target.value });
    }
  };

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    // console.log('patient list: ', formData);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
    // for refactor: externalize below axios code in to thunk
  }, []);

  useEffect(() => {
    dispatch(getPatients());

    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }
    const recordId = viewForm?.data?.id;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        console.log('record info: ', response.data);
        setFormData({
          ...response.data?.data?.mr_admission_and_discharge,
          patientId: response.data?.data?.mr_admission_and_discharge?.id,
          type: 'admission_and_discharge',
        });

        setDefaultData({
          ...response.data?.data?.mr_admission_and_discharge,
          patientId: response.data?.data?.mr_admission_and_discharge?.id,
          type: 'admission_and_discharge',
        });
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      successData,
    } = logs;

    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_admission_and_discharge;
      setFormData(data);
    } */
  }, [logs]);
  console.log(formData, 'name_of_hospital');
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });
  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={formData} viewFormType={viewForm?.type} />
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className='admission-and-discharge'
      >
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                className='text-center'
                placeholder='Enter Here'
                value={formData?.name_of_healthcare_facility}
                onChange={handleChange('name_of_healthcare_facility')}
              />
              <Form.Label>Name of Healthcare Facility</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                className='text-center'
                placeholder='Enter Here'
                value={formData?.healthcare_facility_address}
                onChange={handleChange('healthcare_facility_address')}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='third-row'>
            <h2>{viewForm?.type}</h2>
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col className='second-row'>
            <Form.Group className='input-group'>
              <Form.Label>HRN:</Form.Label>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.hrn || ''}
                onChange={handleChange('hrn')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='main-box-container p-0'>
            <div className='box-cont'>
              <h3>PATIENT&apos;S NAME:</h3>

              <div className='row-group'>
                <Form.Group className='input-group'>
                  <Form.Label>Last Name</Form.Label>
                  <FormControl
                    required
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    onClick={handleShowPatientSelection}
                    value={formData?.patient_lname || ''}
                  // onChange={handleChange('patient_lname')}
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Given Name</Form.Label>
                  <FormControl
                    required
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    onClick={handleShowPatientSelection}
                    value={formData?.patient_fname || ''}
                  // onChange={handleChange('patient_fname')}
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Middle Name</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    onClick={handleShowPatientSelection}
                    value={formData?.patient_mname || ''}
                  // onChange={handleChange('patient_mname')}
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>WARD / RM / BED / SERVICE</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.ward_room_bed_service || ''}
                    onChange={handleChange('ward_room_bed_service')}
                  />
                </Form.Group>
              </div>
            </div>

            <div className='box-cont'>
              <Row>
                <Col sm={6}>
                  <Form.Group className='input-group'>
                    <Form.Label>PERMANENT ADDRESS</Form.Label>
                    <FormControl
                      rows={5}
                      required
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.permanent_address || ''}
                      onChange={handleChange('permanent_address')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group className='input-group'>
                    <Form.Label>Tel. No.</Form.Label>
                    <FormControl
                      required
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.tel_no || ''}
                      onChange={handleChange('tel_no')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1}>
                  <Form.Group className='input-group'>
                    <Form.Label>Sex</Form.Label>

                    <div className='selections'>
                      <Form.Check
                        name='sex'
                        label='Male'
                        value='Male'
                        type='checkbox'
                        disabled={!isEdit}
                        onChange={handleChange('sex')}
                        checked={formData?.sex === 'Male' ? true : false}
                      />
                      <Form.Check
                        name='sex'
                        label='Female'
                        value='Female'
                        type='checkbox'
                        disabled={!isEdit}
                        onChange={handleChange('sex')}
                        checked={formData?.sex === 'Female' ? true : false}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group className='input-group'>
                    <Form.Label>Civil Status</Form.Label>

                    <Row>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            label='S'
                            value='S'
                            name='civil'
                            disabled={!isEdit}
                            onChange={handleChange('civil_status')}
                            checked={formData?.civil_status === 'S' ? true : false}
                          />
                          <Form.Check
                            label='W'
                            value='W'
                            name='civil'
                            disabled={!isEdit}
                            onChange={handleChange('civil_status')}
                            checked={formData?.civil_status === 'W' ? true : false}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            label='D'
                            value='D'
                            name='civil'
                            disabled={!isEdit}
                            onChange={handleChange('civil_status')}
                            checked={formData?.civil_status === 'D' ? true : false}
                          />
                          <Form.Check
                            label='M'
                            value='M'
                            name='civil'
                            disabled={!isEdit}
                            onChange={handleChange('civil_status')}
                            checked={formData?.civil_status === 'M' ? true : false}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            label='SEP'
                            value='SEP'
                            name='civil'
                            onChange={handleChange('civil_status')}
                            disabled={!isEdit}
                            checked={formData?.civil_status === 'SEP' ? true : false}
                          />
                          <Form.Check
                            label='N'
                            value='N'
                            name='civil'
                            disabled={!isEdit}
                            onChange={handleChange('civil_status')}
                            checked={formData?.civil_status === 'N' ? true : false}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            label='C'
                            value='C'
                            name='civil'
                            disabled={!isEdit}
                            onChange={handleChange('civil_status')}
                            checked={formData?.civil_status === 'C' ? true : false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col sm={2}>
                  <Form.Group className='input-group'>
                    <Form.Label>Birthdate</Form.Label>
                    <FormControl
                      required
                      type='date'
                      disabled={!isEdit}
                      value={formData?.date_of_birth || ''}
                      onChange={handleChange('date_of_birth')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1}>
                  <Form.Group className='input-group'>
                    <Form.Label>AGE</Form.Label>
                    <FormControl
                      required
                      type='number'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.age || ''}
                      onChange={handleChange('age')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group className='input-group'>
                    <Form.Label>Birth Place</Form.Label>
                    <FormControl
                      required
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.birth_place || ''}
                      onChange={handleChange('birth_place')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group className='input-group'>
                    <Form.Label>Nationality</Form.Label>
                    <FormControl
                      required
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.nationality || ''}
                      onChange={handleChange('nationality')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group className='input-group'>
                    <Form.Label>Religion</Form.Label>
                    <FormControl
                      required
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.religion || ''}
                      onChange={handleChange('religion')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group className='input-group'>
                    <Form.Label>Occupation</Form.Label>
                    <FormControl
                      required
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.occupation || ''}
                      onChange={handleChange('occupation')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row className='mb-3'>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>EMPLOYER ( Type of Business )</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.employer_type_of_business || ''}
                      onChange={handleChange('employer_type_of_business')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Address</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.employer_address || ''}
                      onChange={handleChange('employer_address')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Telephone No. / CP No.</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.employer_contact_no || ''}
                      onChange={handleChange('employer_contact_no')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Father&apos;s Name</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.father_name || ''}
                      onChange={handleChange('father_name')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Address</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.father_address || ''}
                      onChange={handleChange('father_address')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Telephone No. / CP No.</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.father_contact || ''}
                      onChange={handleChange('father_contact')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Mother&apos;s ( Maiden ) Name</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.mother_name || ''}
                      onChange={handleChange('mother_name')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Address</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.mother_address || ''}
                      onChange={handleChange('mother_address')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Telephone No. / CP No.</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.mother_contact || ''}
                      onChange={handleChange('mother_contact')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className=''>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Spouse Name</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.spouse_name || ''}
                      onChange={handleChange('spouse_name')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Address</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.spouse_address || ''}
                      onChange={handleChange('spouse_address')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>Telephone No. / CP No.</Form.Label>
                    <FormControl
                      type='text'
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.spouse_contact || ''}
                      onChange={handleChange('spouse_contact')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ADMISSION</Form.Label>

                    <div className='multiple-input'>
                      <Form.Group className='input-group mb-3 inline-label'>
                        <Form.Label>Date</Form.Label>
                        <FormControl
                          type='date'
                          disabled={!isEdit}
                          placeholder='Enter here'
                          value={formData?.admission_date || ''}
                          onChange={handleChange('admission_date')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group inline-label'>
                        <Form.Label>Time</Form.Label>
                        <FormControl
                          type='text'
                          disabled={!isEdit}
                          placeholder='Enter here'
                          value={formData?.admission_time || ''}
                          onChange={handleChange('admission_time')}
                        />
                      </Form.Group>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>DISCHARGE</Form.Label>

                    <div className='multiple-input'>
                      <Form.Group className='input-group mb-3 inline-label'>
                        <Form.Label>Date</Form.Label>
                        <FormControl
                          type='date'
                          disabled={!isEdit}
                          placeholder='Enter here'
                          value={formData?.discharge_date || ''}
                          onChange={handleChange('discharge_date')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group inline-label'>
                        <Form.Label>Time</Form.Label>
                        <FormControl
                          type='text'
                          disabled={!isEdit}
                          placeholder='Enter here'
                          value={formData?.discharge_time || ''}
                          onChange={handleChange('discharge_time')}
                        />
                      </Form.Group>
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group className='input-group'>
                    <Form.Label>TOTAL NO. OF DAYS</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.total_no_days || ''}
                      onChange={handleChange('total_no_days')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ADMITTING PHYSICIAN</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.admitting_physician || ''}
                      onChange={handleChange('admitting_physician')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ADMITTING CLERK</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.admitting_clerk || ''}
                      onChange={handleChange('admitting_clerk')}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ADMITTING PHYSICIAN</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.attending_physician || ''}
                      onChange={handleChange('attending_physician')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>TYPE OF ADMISSION</Form.Label>

                    <div className='selections inline-selection'>
                      <Form.Check
                        label='NEW'
                        value='NEW'
                        type='radio'
                        name='admission'
                        disabled={!isEdit}
                        onChange={handleChange('type_of_admission')}
                        checked={formData?.type_of_admission === 'NEW' ? true : false}
                      />
                      <Form.Check
                        label='OLD'
                        value='OLD'
                        type='radio'
                        name='admission'
                        disabled={!isEdit}
                        onChange={handleChange('type_of_admission')}
                        checked={formData?.type_of_admission === 'OLD' ? true : false}
                      />
                      <Form.Check
                        type='radio'
                        name='admission'
                        label='FORMER OPD'
                        value='FORMER OPD'
                        disabled={!isEdit}
                        onChange={handleChange('type_of_admission')}
                        checked={formData?.type_of_admission === 'FORMER OPD' ? true : false}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>REFERRED BY ( Physician / Health Facility )</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.reffered_by || ''}
                      onChange={handleChange('reffered_by')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group inline-label'>
                    <Form.Label>SOCIAL SERVICE CLASSIFICATION</Form.Label>

                    <div className='selections inline-selection'>
                      <Form.Check
                        label='A'
                        value='A'
                        type='radio'
                        disabled={!isEdit}
                        name='social-service'
                        onChange={handleChange('social_service_classification')}
                        checked={formData?.social_service_classification === 'A' ? true : false}
                      />
                      <Form.Check
                        label='B'
                        value='B'
                        type='radio'
                        disabled={!isEdit}
                        name='social-service'
                        onChange={handleChange('social_service_classification')}
                        checked={formData?.social_service_classification === 'B' ? true : false}
                      />
                      <Form.Check
                        label='C1'
                        value='C1'
                        type='radio'
                        disabled={!isEdit}
                        name='social-service'
                        onChange={handleChange('social_service_classification')}
                        checked={formData?.social_service_classification === 'C1' ? true : false}
                      />
                      <Form.Check
                        label='C2'
                        value='C2'
                        type='radio'
                        disabled={!isEdit}
                        name='social-service'
                        onChange={handleChange('social_service_classification')}
                        checked={formData?.social_service_classification === 'C2' ? true : false}
                      />
                      <Form.Check
                        label='C3'
                        value='C3'
                        type='radio'
                        disabled={!isEdit}
                        name='social-service'
                        onChange={handleChange('social_service_classification')}
                        checked={formData?.social_service_classification === 'C3' ? true : false}
                      />
                      <Form.Check
                        label='D'
                        value='D'
                        type='radio'
                        disabled={!isEdit}
                        name='social-service'
                        onChange={handleChange('social_service_classification')}
                        checked={formData?.social_service_classification === 'D' ? true : false}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ALERT : ALERGIC TO</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.allergic_to || ''}
                      onChange={handleChange('allergic_to')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>
                      HOSPITALIZATION PLAN <br /> ( Company / Industrial Name )
                    </Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.hospitalization_plan || ''}
                      onChange={handleChange('hospitalization_plan')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>HEALTH INSURANCE NAME</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.health_insurance_name || ''}
                      onChange={handleChange('health_insurance_name')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>PHIC</Form.Label>

                    <Row>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            type='radio'
                            name='phic'
                            label='SSS'
                            value='SSS'
                            checked={formData?.phic === 'SSS' ? true : false}
                            onChange={handleChange('phic')}
                            disabled={!isEdit}
                          />
                          <Form.Check
                            type='radio'
                            name='phic'
                            label='SSS Dependent'
                            value='SSS Dependent'
                            checked={formData?.phic === 'SSS Dependent' ? true : false}
                            onChange={handleChange('phic')}
                            disabled={!isEdit}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            name='phic'
                            type='radio'
                            label='GSIS'
                            value='GSIS'
                            disabled={!isEdit}
                            onChange={handleChange('phic')}
                            checked={formData?.phic === 'GSIS' ? true : false}
                          />
                          <Form.Check
                            type='radio'
                            name='phic'
                            label='GSIS Dependent'
                            value='GSIS Dependent'
                            disabled={!isEdit}
                            onChange={handleChange('phic')}
                            checked={formData?.phic === 'GSIS Dependent' ? true : false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>DATA FURNISHED BY</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.data_furnished_by || ''}
                      onChange={handleChange('data_furnished_by')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ADDRESS OF INFORMANT</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.address_of_informat || ''}
                      onChange={handleChange('address_of_informat')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>RELATION TO PATIENT</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.relation_to_patient || ''}
                      onChange={handleChange('relation_to_patient')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ADMISSION DIAGNOSIS</Form.Label>
                    <FormControl
                      as='textarea'
                      rows={5}
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.admission_diagnosis || ''}
                      onChange={handleChange('admission_diagnosis')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>DISCHARGE DIAGNOSIS</Form.Label>

                    <div className='multiple-input'>
                      <Form.Group className='input-group mb-3'>
                        <Form.Label>PRINCIPAL DIAGNOSIS</Form.Label>
                        <FormControl
                          placeholder='Enter here'
                          value={formData?.discharge_diagnosis || ''}
                          onChange={handleChange('discharge_diagnosis')}
                          disabled={!isEdit}
                        />
                      </Form.Group>

                      <Form.Group className='input-group'>
                        <Form.Label>OTHER DIAGNOSIS</Form.Label>
                        <FormControl
                          placeholder='Enter here'
                          value={formData?.other_diagnosis || ''}
                          onChange={handleChange('other_diagnosis')}
                          disabled={!isEdit}
                        />
                      </Form.Group>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ICD / RUV CODE:</Form.Label>
                    <FormControl
                      placeholder='Enter here'
                      value={formData?.icd_ruv_code || ''}
                      onChange={handleChange('icd_ruv_code')}
                      disabled={!isEdit}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>PRINCIPAL OPERATION / PROCEDURE</Form.Label>
                    <FormControl
                      placeholder='Enter here'
                      value={formData?.principal_operation_procedure || ''}
                      onChange={handleChange('principal_operation_procedure')}
                      disabled={!isEdit}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ICD / RUV CODE:</Form.Label>
                    <FormControl
                      placeholder='Enter here'
                      value={formData?.principal_operation_procedure_icd_ruv_code || ''}
                      onChange={handleChange('principal_operation_procedure_icd_ruv_code')}
                      disabled={!isEdit}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>OTHER OPERATION (S) PROCEDURE (S)</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.other_principal_operation_procedure || ''}
                      onChange={handleChange('other_principal_operation_procedure')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ICD / RUV CODE:</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.other_principal_operation_procedure_icd_ruv_code || ''}
                      onChange={handleChange('other_principal_operation_procedure_icd_ruv_code')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>ACCIDENT / INJURIES / POISONING</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter here'
                      value={formData?.accident_injuries_poisoning || ''}
                      onChange={handleChange('accident_injuries_poisoning')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>DISPOSITION</Form.Label>

                    <Row>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            name='disposition'
                            label='Discharge'
                            value='Discharge'
                            checked={formData?.disposition === 'Discharge' ? true : false}
                            onChange={handleChange('disposition')}
                            disabled={!isEdit}
                          />
                          <Form.Check
                            name='disposition'
                            label='Transferred'
                            value='Transferred'
                            checked={formData?.disposition === 'Transferred' ? true : false}
                            onChange={handleChange('disposition')}
                            disabled={!isEdit}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            name='disposition'
                            label='HAMA'
                            value='HAMA'
                            checked={formData?.disposition === 'HAMA' ? true : false}
                            onChange={handleChange('disposition')}
                            disabled={!isEdit}
                          />
                          <Form.Check
                            name='disposition'
                            label='Absconded'
                            value='Absconded'
                            checked={formData?.disposition === 'Absconded' ? true : false}
                            onChange={handleChange('disposition')}
                            disabled={!isEdit}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className='input-group'>
                    <Form.Label>RESULTS</Form.Label>

                    <Row>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            type='radio'
                            name='reults'
                            label='Recovered'
                            value='Recovered'
                            checked={formData?.result === 'Recovered' ? true : false}
                            onChange={handleChange('result')}
                            disabled={!isEdit}
                          />
                          <Form.Check
                            type='radio'
                            name='reults'
                            label='Improved'
                            value='Improved'
                            checked={formData?.result === 'Improved' ? true : false}
                            onChange={handleChange('result')}
                            disabled={!isEdit}
                          />
                          <Form.Check
                            type='radio'
                            name='reults'
                            label='Unimproved'
                            value='Unimproved'
                            checked={formData?.result === 'Unimproved' ? true : false}
                            onChange={handleChange('result')}
                            disabled={!isEdit}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            name='reults'
                            label='DIED'
                            value='DIED'
                            checked={formData?.is_died === 'DIED' ? true : false}
                            onChange={checkChange('is_died')}
                            disabled={!isEdit}
                          />
                          <Form.Check
                            name='reults'
                            label='-48 hours'
                            value='-48 hours'
                            checked={
                              formData?.is_died_less_than_48_hours === '-48 hours' ? true : false
                            }
                            onChange={checkChange('is_died_less_than_48_hours')}
                            disabled={!isEdit}
                          />
                          <Form.Check
                            name='reults'
                            label='+48 hours'
                            value='+48 hours'
                            checked={
                              formData?.is_died_greater_than_48_hours === '+48 hours' ? true : false
                            }
                            onChange={checkChange('is_died_greater_than_48_hours')}
                            disabled={!isEdit}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <Form.Check
                            name='reults'
                            label='Autopsy'
                            value='Autopsy'
                            checked={formData?.is_autopsy === 'Autopsy' ? true : false}
                            onChange={checkChange('is_autopsy')}
                            disabled={!isEdit}
                          />

                          <Form.Check
                            name='reults'
                            label='No'
                            value='No'
                            disabled={!isEdit}
                            onChange={checkChange('is_no')}
                            checked={formData?.is_no === 'No' ? true : false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>PRINT CERTIFICATE</Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          disabled={trigger.trigger}
                          className='save'
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setFormData(defaultData);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>
      <PatientSelection
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
        patientSelection={patientSelection}
        fields={formData}
        setFields={setFormData}
      />
    </>
  );
};

export default AdmisionAndDischarge;
