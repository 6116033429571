/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { Modal, Row, Col, Button, Form, InputGroup, FormControl, Table } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';

// import assets below
import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import PrintContent from './PrintComponents/MedicalCertificate';
// main component
const FormModal = ({ setViewForm, tableData, show, setShow, setShowScreen }) => {
  const handleShow = () => setShow(false);

  const printRef = useRef(null);

  if (!tableData) {
    return;
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  return (
    <Modal show={show} size='lg' className='form-modal'>
      <Modal.Body>
        <div className='form-modal-header'>
          <div className='d-flex header'>
            <p>{tableData?.type}</p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
          <div style={{ display: 'none' }}>
            <PrintContent ref={printRef} />
          </div>
        </div>

        <div className='form'>
          <Row>
            <Col className='header-search'>
              <p>{`List of Records as of ${tableData?.date}`}</p>

              <Form.Group className='mb-2 basic-search'>
                <InputGroup>
                  <InputGroup.Text className='bg-white'>
                    <MagnifyingGlassSVG />
                  </InputGroup.Text>
                  <FormControl type='search' className='bg-white' placeholder='Patient ID / Name' />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className='form-table-modal'>
            <Col>
              <TableUI>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Patient ID</th>
                      <th>Patient Name</th>
                      <th>Record Form Id</th>
                      <th>Record</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.tableData?.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.patientId}</td>
                          <td>{data?.patient?.full_name ?? ''}</td>
                          <td>{data?.id}</td>
                          <td>{data?.name}</td>
                          <td className='table-buttons'>
                            <Button
                              onClick={() => {
                                setShow(false);
                                setShowScreen(1);
                                setViewForm({
                                  data: data,
                                  type: tableData?.type,
                                  patientID: data?.patientId,
                                });
                              }}
                              className='view'
                            >
                              View
                            </Button>
                            <Button className='download'>Download Certificate</Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TableUI>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col className='buttons-cont'>
              <Button onClick={handleShow} className='close'>
                Close
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

FormModal.defaultProps = {
  show: false,
  setShow: () => {},
  tableData: {},
  setViewForm: () => {},
};

FormModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  tableData: PropTypes.object,
  setViewForm: PropTypes.func,
};

export default FormModal;
