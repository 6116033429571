/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-spacing */
/* eslint-disable no-unneeded-ternary  */
/* eslint-disable comma-dangle */
//  import core & vendor packages below
import React from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

// import assets below
import './index.scss';

// main component
const headerTitleStyle = {
  fontWeight: 'bold',
  color: 'black',
  fontSize: '16px',
};
const headerSubtitleStyle = {
  fontWeight: 'bold',
  color: 'black',
  fontSize: '14px',
  marginTop: '5px',
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
    process.env;
  const {
    age,
    attending_physician_date,
    attending_physician_name,
    attending_physician_time,
    chief_complaint,
    createdAt,
    cv_is_chest_pain,
    cv_is_orthropnea,
    cv_is_palpitations,
    cv_is_pedal_edema,
    cv_is_pnd,
    date,
    date_of_birth,
    diagnostics_is_alt_ast,
    diagnostics_is_alt_ast_2,
    diagnostics_is_cbc,
    diagnostics_is_cbc_2,
    diagnostics_is_cbg,
    diagnostics_is_cbg_2,
    diagnostics_is_cpk_mb_cpk_mm_cpk_total,
    diagnostics_is_cpk_mb_cpk_mm_cpk_total_2,
    diagnostics_is_protime_ptt,
    diagnostics_is_protime_ptt_2,
    diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p,
    diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p_2,
    diagnostics_is_top_i_t,
    diagnostics_is_top_i_t_2,
    diagnostics_is_uric_acid,
    diagnostics_is_uric_acid_2,
    diagnostics_is_xray_2d_eco,
    diagnostics_is_xray_abdomen,
    diagnostics_is_xray_cervical,
    diagnostics_is_xray_chest,
    diagnostics_is_xray_contast,
    diagnostics_is_xray_cranial,
    diagnostics_is_xray_hbt,
    diagnostics_is_xray_pelvic,
    diagnostics_is_xray_plain,
    diagnostics_is_xray_transvaginal,
    diagnostics_is_xray_whole_abdomen,
    diagnostics_islecg,
    discharge_diagnosis,
    discharge_plans,
    ent_is_congestion,
    ent_is_ear_ache,
    ent_is_ear_discharge,
    ent_is_epistaxis,
    ent_is_hoarseness,
    ent_is_sore_throat,
    eye_is_blurred_vission,
    eye_is_diplopia,
    eye_is_itching,
    eye_is_redness,
    family_is_asthma,
    family_is_cancer,
    family_is_cardiac,
    family_is_diabetes,
    family_is_hyper_tension,
    family_is_none,
    family_is_ptb,
    follow_up_clinic,
    follow_up_date,
    follow_up_time,
    general_is_chills,
    general_is_fever,
    general_is_nausea,
    general_is_weakness,
    gi_is_abdominal_pain,
    gi_is_constipation,
    gi_is_hematemesis,
    gi_is_hematochezia,
    gi_is_melena,
    gi_is_vomitting,
    gu_is_dysuria,
    gu_is_frequency,
    gu_is_nocturia,
    gu_is_vaginal_bleeding,
    gu_is_vaginal_discharge,
    healthcare_facility_address,
    historian_is_concerned_citizen,
    historian_is_ems,
    historian_is_family,
    historian_is_friend,
    historian_is_guardian,
    historian_is_others,
    historian_is_parent,
    historian_is_patient,
    historian_is_private_md,
    history_of_present_illness,
    hospital_name,
    hrn,
    id,
    initial_assessment,
    is_absconded,
    is_admit,
    is_ambulance,
    is_dead_on_arrival,
    is_er_death,
    is_home_againts_medical_advice,
    is_private,
    is_self_confuction,
    is_transfer_to_hospital,
    is_treated_and_discharge,
    medicalRecordId,
    medications,
    medications_2,
    mode_of_arrival,
    mr_emergency_treatment_nurse_notes,
    ms_is_back_pain,
    ms_is_hip_pain,
    ms_is_joint_pain,
    ms_is_neck_pain,
    ms_is_shoulder_pain,
    name_of_healthcare_facility,
    neuro_is_blackout,
    neuro_is_headache,
    neuro_is_numbness,
    neuro_is_seizure,
    neuro_is_unsteady_galt,
    nurse_notes,
    past_medical_surgical_is_asthma,
    past_medical_surgical_is_cardiac,
    past_medical_surgical_is_diabetes,
    past_medical_surgical_is_hyper_tension,
    past_medical_surgical_is_icu_admission,
    past_medical_surgical_is_none,
    past_medical_surgical_is_or,
    past_medical_surgical_is_ptb,
    patient_fname,
    patient_lname,
    patient_mname,
    physical_examination,
    physical_examination_others,
    psych_alcoholic_bev_drinker,
    psych_allergies,
    psych_illicit_drug_use,
    psych_is_anxious,
    psych_is_depression,
    psych_is_hallucination,
    psych_is_not_sleeping,
    psych_is_stress,
    psych_smoker_ppd_x_yrs,
    referred_by,
    refferals_reason_for_referral,
    refferals_reffered_by,
    refferals_service,
    refferals_time,
    resp_is_cough,
    resp_is_dob,
    resp_is_hemoptysis,
    resp_is_sputum,
    resp_is_wheezing,
    results_cbc,
    results_chemistry,
    results_ekg,
    results_radiographics,
    sex,
    skin_is_breast_discharge,
    skin_is_breast_masses,
    skin_is_rash,
    skin_is_swelling,
    social_is_breast_discharge,
    social_is_breast_masses,
    social_is_rash,
    social_is_swelling,
    special_instructions,
    therapeutics_diagnosis,
    therapeutics_diet,
    therapeutics_ivf,
    therapeutics_lpm_via,
    therapeutics_medications,
    therapeutics_monitor,
    therapeutics_npo,
    therapeutics_oxygen,
    therapeutics_signature,
    therapeutics_time_given,
    time,
    trage_date,
    trage_time,
    triage_officer_complaint,
    updatedAt,
    vital_sign__t,
    vital_sign__w,
    vital_sign_bp,
    vital_sign_hr_rr,
  } = data;
  return (
    <div className='print_body' ref={ref}>
      <header>
        <div className='header_logo'>
          <img src={REACT_APP_LOGO} alt='print_logo' />
        </div>
        {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
        <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
        <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
      <Table bordered>
        <thead>
          <tr>
            <th>{viewFormType}</th>
          </tr>
        </thead>
        <tbody>
          <Container>
            <Row className='mb-4'>
              <Col className='first-row'>
                <div className='input-group'>
                  <p>{name_of_healthcare_facility}</p>
                  <label>Name of Healthcare Facility</label>
                </div>

                <div className='input-group'>
                  <p>{healthcare_facility_address}</p>
                  <label>Address</label>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='third-row'>
                <h2>I. TRIAGE RECORD</h2>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>PHYSICAL EXAMINATION</h3>

                <div className='box-cont'>
                  <p className='small-header'>NAME ( Last, Given, Middle )</p>

                  <div className='row-group mb-3'>
                    <div className='input-group big'>
                      <label>Last Name</label>
                      <p>{patient_lname}</p>
                    </div>

                    <div className='input-group big'>
                      <label>First Name</label>
                      <p>{patient_fname}</p>
                    </div>

                    <div className='input-group big'>
                      <label>Middle Name</label>
                      <p>{patient_mname}</p>
                    </div>
                  </div>

                  <div className='row-group'>
                    <div className='input-group small'>
                      <label>Age</label>
                      <p>{age}</p>
                    </div>

                    <div className='input-group big'>
                      <label>Sex</label>

                      <div className='checkbox'>
                        <div className='selections'>
                          <p>{sex}</p>
                        </div>
                      </div>
                    </div>

                    <div className='input-group big'>
                      <label>Date of Birth</label>
                      <p>{date_of_birth}</p>
                    </div>
                  </div>
                </div>

                <div className='box-cont d-flex'>
                  <div className='info'>
                    <h5>REFERRED BY</h5>

                    <div className='checkbox input-group'>
                      <div className='selections'>
                        <Form.Check
                          type='radio'
                          name='referred_by'
                          label='Self'
                          value='Self'
                          checked={referred_by === 'Self' ? true : false}
                        />
                        <Form.Check
                          type='radio'
                          name='referred_by'
                          label='OPD'
                          value='OPD'
                          checked={referred_by === 'OPD' ? true : false}
                        />
                        <Form.Check
                          type='radio'
                          name='referred_by'
                          label='Private MD'
                          value='Private MD'
                          checked={referred_by === 'Private MD' ? true : false}
                        />
                        <Form.Check
                          type='radio'
                          name='referred_by'
                          label='Hospital'
                          value='Hospital'
                          checked={referred_by === 'Hospital' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info'>
                    <h5>MODE OF ARRIVAL</h5>

                    <div className='checkbox input-group '>
                      <div className='selections'>
                        <Form.Check
                          type='radio'
                          name='mode_of_arrivals'
                          label='Ambulance'
                          value='Ambulance'
                          checked={mode_of_arrival === 'Ambulance' ? true : false}
                        />
                        <Form.Check
                          type='radio'
                          name='mode_of_arrivals'
                          label='Walk - in'
                          value='Walk - in'
                          checked={mode_of_arrival === 'Walk - in' ? true : false}
                        />
                        <Form.Check
                          type='radio'
                          name='mode_of_arrivals'
                          label='Private Vehicle'
                          value='Private Vehicle'
                          checked={mode_of_arrival === 'Private Vehicle' ? true : false}
                        />
                        <Form.Check
                          type='radio'
                          name='mode_of_arrivals'
                          label='Police Escort'
                          value='Police Escort'
                          checked={mode_of_arrival === 'Police Escort' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info'>
                    <h5>HISTORIAN</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Patient'
                          label='Patient'
                          value='Patient'
                          checked={historian_is_patient === 'Patient' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Parent'
                          label='Parent'
                          value='Parent'
                          checked={historian_is_parent === 'Parent' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Family'
                          label='Family'
                          value='Family'
                          checked={historian_is_family === 'Family' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Friend'
                          label='Friend'
                          value='Friend'
                          checked={historian_is_friend === 'Friend' ? true : false}
                        />
                      </div>

                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Private MD'
                          label='Private MD'
                          value='Private MD'
                          checked={historian_is_private_md === 'Private MD' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Guardian'
                          label='Guardian'
                          value='Guardian'
                          checked={historian_is_guardian === 'Guardian' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='EMS'
                          label='EMS'
                          value='EMS'
                          checked={historian_is_ems === 'EMS' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Concerned Citizen'
                          label='Concerned Citizen'
                          value='Concerned Citizen'
                          checked={
                            historian_is_concerned_citizen === 'Concerned Citizen' ? true : false
                          }
                        />
                      </div>

                      <div className='selections justify-content-start h-100'>
                        <Form.Check
                          type='checkbox'
                          id='Others'
                          label='Others'
                          value='Others'
                          checked={historian_is_others === 'Others' ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box-cont'>
                  <p className='small-header'>VITAL SIGNS</p>

                  <div className='row-group mb-3' style={{ flexWrap: 'wrap' }}>
                    <div className='input-group small'>
                      <label>HR / RR</label>
                      <p>{vital_sign_hr_rr}</p>
                    </div>
                    <div className='input-group small'>
                      <label>BP</label>
                      <p>{vital_sign_bp}</p>
                    </div>

                    <div className='input-group small'>
                      <label>T</label>
                      <p>{vital_sign__t}</p>
                    </div>

                    <div className='input-group small'>
                      <label>Weight</label>
                      <p>{vital_sign__w}</p>
                    </div>
                  </div>
                </div>

                <div className='box-cont'>
                  <div className='row-group'>
                    <div className='input-group big'>
                      <label>CHIEF COMPLAINT</label>
                      <p>{chief_complaint}</p>
                    </div>
                  </div>
                </div>

                <div className='box-cont'>
                  <div className='row-group'>
                    <div className='input-group big'>
                      <label>HISTORY OF PRESENT ILLNESS</label>
                      <p>{history_of_present_illness}</p>
                    </div>
                  </div>
                </div>

                <h3>PHYSICAL EXAMINATION</h3>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <h5>GENERAL</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Fever'
                          label='Fever'
                          value='Fever'
                          checked={general_is_fever === 'Fever' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Chills'
                          label='Chills'
                          value='Chills'
                          checked={general_is_chills === 'Chills' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Weakness'
                          label='Weakness'
                          value='Weakness'
                          checked={general_is_weakness === 'Weakness' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Nausea'
                          label='Nausea'
                          value='Nausea'
                          checked={general_is_nausea === 'Nausea' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>EYE</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Redness'
                          label='Redness'
                          value='Redness'
                          checked={eye_is_redness === 'Redness' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Itching'
                          label='Itching'
                          value='Itching'
                          checked={eye_is_itching === 'Itching' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Blurred Vision'
                          label='Blurred Vision'
                          value='Blurred Vision'
                          checked={eye_is_blurred_vission === 'Blurred Vision' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Diplopia'
                          label='Diplopia'
                          value='Diplopia'
                          checked={eye_is_diplopia === 'Diplopia' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>ENT</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Congestion'
                          label='Congestion'
                          value='Congestion'
                          checked={ent_is_congestion === 'Congestion' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Epistaxis'
                          label='Epistaxis'
                          value='Epistaxis'
                          checked={ent_is_epistaxis === 'Epistaxis' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Sore Throat'
                          label='Sore Throat'
                          value='Sore Throat'
                          checked={ent_is_sore_throat === 'Sore Throat' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hoarseness'
                          label='Hoarseness'
                          value='Hoarseness'
                          checked={ent_is_hoarseness === 'Hoarseness' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Ear Ache'
                          label='Ear Ache'
                          value='Ear Ache'
                          checked={ent_is_ear_ache === 'Ear Ache' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Ear Discharge'
                          label='Ear Discharge'
                          value='Ear Discharge'
                          checked={ent_is_ear_discharge === 'Ear Discharge' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>CV</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Chest Pain'
                          label='Chest Pain'
                          value='Chest Pain'
                          checked={cv_is_chest_pain === 'Chest Pain' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Palpitations'
                          label='Palpitations'
                          value='Palpitations'
                          checked={cv_is_palpitations === 'Palpitations' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Orthopnea'
                          label='Orthopnea'
                          value='Orthopnea'
                          checked={cv_is_orthropnea === 'Orthopnea' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Pedal Edema'
                          label='Pedal Edema'
                          value='Pedal Edema'
                          checked={cv_is_pedal_edema === 'Pedal Edema' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='PND'
                          label='PND'
                          value='PND'
                          checked={cv_is_pnd === 'PND' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>RESP</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='DOB'
                          label='DOB'
                          value='DOB'
                          checked={resp_is_dob === 'DOB' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Cough'
                          label='Cough'
                          value='Cough'
                          checked={resp_is_cough === 'Cough' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Sputum'
                          label='Sputum'
                          value='Sputum'
                          checked={resp_is_sputum === 'Sputum' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hemoptysis'
                          label='Hemoptysis'
                          value='Hemoptysis'
                          checked={resp_is_hemoptysis === 'Hemoptysis' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Wheezing'
                          label='Wheezing'
                          value='Wheezing'
                          checked={resp_is_wheezing === 'Wheezing' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>GI</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Abdominal pain'
                          label='Abdominal pain'
                          value='Abdominal pain'
                          checked={gi_is_abdominal_pain === 'Abdominal pain' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Vomiting'
                          label='Vomiting'
                          value='Vomiting'
                          checked={gi_is_vomitting === 'Vomiting' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Constipation'
                          label='Constipation'
                          value='Constipation'
                          checked={gi_is_constipation === 'Constipation' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Melena'
                          label='Melena'
                          value='Melena'
                          checked={gi_is_melena === 'Melena' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hematochezia'
                          label='Hematochezia'
                          value='Hematochezia'
                          checked={gi_is_hematochezia === 'Hematochezia' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hematemesis'
                          label='Hematemesis'
                          value='Hematemesis'
                          checked={gi_is_hematemesis === 'Hematemesis' ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <h5>GU</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Dysuria'
                          label='Dysuria'
                          value='Dysuria'
                          checked={gu_is_dysuria === 'Dysuria' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Frequency'
                          label='Frequency'
                          value='Frequency'
                          checked={gu_is_frequency === 'Frequency' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Nocturia'
                          label='Nocturia'
                          value='Nocturia'
                          checked={gu_is_nocturia === 'Nocturia' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Vaginal Discharge'
                          label='Vaginal Discharge'
                          value='Vaginal Discharge'
                          checked={gu_is_vaginal_discharge === 'Vaginal Discharge' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Vaginal Bleeding'
                          label='Vaginal Bleeding'
                          value='Vaginal Bleeding'
                          checked={gu_is_vaginal_bleeding === 'Vaginal Bleeding' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>NEURO</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Headache'
                          label='Headache'
                          value='Headache'
                          checked={neuro_is_headache === 'Headache' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Blackout'
                          label='Blackout'
                          value='Blackout'
                          checked={neuro_is_blackout === 'Blackout' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Numbness'
                          label='Numbness'
                          value='Numbness'
                          checked={neuro_is_numbness === 'Numbness' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Unsteady Galt'
                          label='Unsteady Galt'
                          value='Unsteady Galt'
                          checked={neuro_is_unsteady_galt === 'Unsteady Galt' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Seizure'
                          label='Seizure'
                          value='Seizure'
                          checked={neuro_is_seizure === 'Seizure' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>MS</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Neck Pain'
                          label='Neck Pain'
                          value='Neck Pain'
                          checked={ms_is_neck_pain === 'Neck Pain' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Back Pain'
                          label='Back Pain'
                          value='Back Pain'
                          checked={ms_is_back_pain === 'Back Pain' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hip Pain'
                          label='Hip Pain'
                          value='Hip Pain'
                          checked={ms_is_hip_pain === 'Hip Pain' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Shoulder Pain'
                          label='Shoulder Pain'
                          value='Shoulder Pain'
                          checked={ms_is_shoulder_pain === 'Shoulder Pain' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Joint Pain'
                          label='Joint Pain'
                          value='Joint Pain'
                          checked={ms_is_joint_pain === 'Joint Pain' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>SKIN</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Rash'
                          label='Rash'
                          value='Rash'
                          checked={skin_is_rash === 'Rash' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Swelling'
                          label='Swelling'
                          value='Swelling'
                          checked={skin_is_swelling === 'Swelling' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Breast Discharge'
                          label='Breast Discharge'
                          value='Breast Discharge'
                          checked={skin_is_breast_discharge === 'Breast Discharge' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Breast Masses'
                          label='Breast Masses'
                          value='Breast Masses'
                          checked={skin_is_breast_masses === 'Breast Masses' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>PSYCH</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Anxious'
                          label='Anxious'
                          value='Anxious'
                          checked={psych_is_anxious === 'Anxious' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Depression'
                          label='Depression'
                          value='Depression'
                          checked={psych_is_depression === 'Depression' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hallucination'
                          label='Hallucination'
                          value='Hallucination'
                          checked={psych_is_hallucination === 'Hallucination' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Stress'
                          label='Stress'
                          value='Stress'
                          checked={psych_is_stress === 'Stress' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Not Sleeping'
                          label='Not Sleeping'
                          value='Not Sleeping'
                          checked={psych_is_not_sleeping === 'Not Sleeping' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>OTHERS</h5>
                    <div className='row-group'>
                      <div className='input-group big'>
                        <p>{physical_examination_others}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box-cont'>
                  <div className='text-group'>
                    <h4>ALL SYSTEMS REVIEWED</h4>
                    <p>Negative</p>
                    <p>All other systems negative</p>
                    <p>Incomplete due to:</p>
                    <p>Loss of Consciousness / Intubated / Exposure to toxic chemicals</p>
                  </div>
                </div>

                <h3>REVIEW OF SYSTEMS</h3>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <h5>PAST MEDICAL / SURGICAL</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='None'
                          label='None'
                          value='None'
                          checked={past_medical_surgical_is_none === 'None' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='PTB'
                          label='PTB'
                          value='PTB'
                          checked={past_medical_surgical_is_ptb === 'PTB' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Diabetes'
                          label='Diabetes'
                          value='Diabetes'
                          checked={past_medical_surgical_is_diabetes === 'Diabetes' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hyper Tension'
                          label='Hyper Tension'
                          value='Hyper Tension'
                          checked={
                            past_medical_surgical_is_hyper_tension === 'Hyper Tension'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='Asthma'
                          label='Asthma'
                          value='Asthma'
                          checked={past_medical_surgical_is_asthma === 'Asthma' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Cardiac'
                          label='Cardiac'
                          value='Cardiac'
                          checked={past_medical_surgical_is_cardiac === 'Cardiac' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='ICU Admission'
                          label='ICU Admission'
                          value='ICU Admission'
                          checked={
                            past_medical_surgical_is_icu_admission === 'ICU Admission'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='OR'
                          label='OR'
                          value='OR'
                          checked={past_medical_surgical_is_or === 'OR' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>MEDICATIONS</h5>
                    <div className='row-group'>
                      <div className='input-group big'>
                        <p>{medications}</p>
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>FAMILY</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='None'
                          label='None'
                          value='None'
                          checked={family_is_none === 'None' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='PTB'
                          label='PTB'
                          value='PTB'
                          checked={family_is_ptb === 'PTB' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Diabetes'
                          label='Diabetes'
                          value='Diabetes'
                          checked={family_is_diabetes === 'Diabetes' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Hyper Tension'
                          label='Hyper Tension'
                          value='Hyper Tension'
                          checked={family_is_hyper_tension === 'Hyper Tension' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Asthma'
                          label='Asthma'
                          value='Asthma'
                          checked={family_is_asthma === 'Asthma' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Cardiac'
                          label='Cardiac'
                          value='Cardiac'
                          checked={family_is_cardiac === 'Cardiac' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Cancer'
                          label='Cancer'
                          value='Cancer'
                          checked={family_is_cancer === 'Cancer' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <h5>SOCIAL</h5>

                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Rash'
                          label='Rash'
                          value='Rash'
                          checked={social_is_rash === 'Rash' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Swelling'
                          label='Swelling'
                          value='Swelling'
                          checked={social_is_swelling === 'Swelling' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Breast Discharge'
                          label='Breast Discharge'
                          value='Breast Discharge'
                          checked={social_is_breast_discharge === 'Breast Discharge' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Breast Masses'
                          label='Breast Masses'
                          value='Breast Masses'
                          checked={social_is_breast_masses === 'Breast Masses' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className='info border-left'>
                <h5>PSYCH</h5>

                <div className='checkbox'>
                  <div className='selections'>
                    <Form.Check type='checkbox' id='Smoker ppd x yrs' label='Smoker ppd x yrs' />
                    <Form.Check
                      type='checkbox'
                      id='Alcocholic bev Drinker'
                      label='Alcocholic bev Drinker'
                    />
                    <Form.Check type='checkbox' id='Illicit drug use' label='Illicit drug use' />
                    <Form.Check type='checkbox' id='Allergies' label='Allergies' />
                  </div>
                </div>
              </div> */}
                  <div className='info border-left'>
                    <h5></h5>
                  </div>
                  <div className='info '>
                    <h5></h5>
                  </div>
                </div>

                <div className='box-cont'>
                  <div className='row-group'>
                    <div className='input-group big'>
                      <label>TRIAGE OFFICER COMPLAINT</label>
                      <p>{triage_officer_complaint}</p>
                    </div>

                    <div className='input-group big'>
                      <label>DATE</label>
                      <p>{trage_date}</p>
                    </div>

                    <div className='input-group big'>
                      <label>TIME</label>
                      <p>{trage_time}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='third-row'>
                <h2>II. EMERGENCY ASSESSMENT AND DISPOSITION</h2>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>PHYSICAL EXAMINATION</h3>

                <div className='box-cont d-flex p-4'>
                  <div className='input-group big'>
                    <p>{physical_examination}</p>
                  </div>
                </div>

                <div className='box-cont'>
                  <div className='row-group'>
                    <div className='input-group big'>
                      <label>INITIAL ASSESSMENT</label>
                      <p>{initial_assessment}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>DIAGNOSTICS</h3>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='CBG'
                          label='CBG'
                          value='CBG'
                          checked={diagnostics_is_cbg === 'CBG' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='CBC'
                          label='CBC'
                          value='CBC'
                          checked={diagnostics_is_cbc === 'CBC' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='RBS BUN Crea Na K Cl Ca Mg P'
                          label='RBS BUN Crea Na K Cl Ca Mg P'
                          value='RBS BUN Crea Na K Cl Ca Mg P'
                          checked={
                            diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p ===
                            'RBS BUN Crea Na K Cl Ca Mg P'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='Uric Acid LDH Chol TG LDL HDL'
                          label='Uric Acid LDH Chol TG LDL HDL'
                          value='Uric Acid LDH Chol TG LDL HDL'
                          checked={
                            diagnostics_is_uric_acid === 'Uric Acid LDH Chol TG LDL HDL'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='ALT AST alk phos'
                          label='ALT AST alk phos'
                          value='ALT AST alk phos'
                          checked={diagnostics_is_alt_ast === 'ALT AST alk phos' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Protime / PTT'
                          label='Protime / PTT'
                          value='Protime / PTT'
                          checked={diagnostics_is_protime_ptt === 'Protime / PTT' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='CPK MB CPK MM CPK Total'
                          label='CPK MB CPK MM CPK Total'
                          value='CPK MB CPK MM CPK Total'
                          checked={
                            diagnostics_is_cpk_mb_cpk_mm_cpk_total === 'CPK MB CPK MM CPK Total'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='Trop I T'
                          label='Trop I T'
                          value='Trop I T'
                          checked={diagnostics_is_top_i_t === 'Trop I T' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='CBG'
                          label='CBG'
                          value='CBG'
                          checked={diagnostics_is_cbg_2 === 'CBG' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='CBC'
                          label='CBC'
                          value='CBC'
                          checked={diagnostics_is_cbc_2 === 'CBC' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='RBS BUN Crea Na K Cl Ca Mg P'
                          label='RBS BUN Crea Na K Cl Ca Mg P'
                          value='RBS BUN Crea Na K Cl Ca Mg P'
                          checked={
                            diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p_2 ===
                            'RBS BUN Crea Na K Cl Ca Mg P'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='Uric Acid LDH Chol TG LDL HDL'
                          label='Uric Acid LDH Chol TG LDL HDL'
                          value='Uric Acid LDH Chol TG LDL HDL'
                          checked={
                            diagnostics_is_uric_acid_2 === 'Uric Acid LDH Chol TG LDL HDL'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='ALT AST alk phos'
                          label='ALT AST alk phos'
                          value='ALT AST alk phos'
                          checked={diagnostics_is_alt_ast_2 === 'ALT AST alk phos' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='Protime / PTT'
                          label='Protime / PTT'
                          value='Protime / PTT'
                          checked={diagnostics_is_protime_ptt_2 === 'Protime / PTT' ? true : false}
                        />
                        <Form.Check
                          type='checkbox'
                          id='CPK MB CPK MM CPK Total'
                          label='CPK MB CPK MM CPK Total'
                          value='CPK MB CPK MM CPK Total'
                          checked={
                            diagnostics_is_cpk_mb_cpk_mm_cpk_total_2 === 'CPK MB CPK MM CPK Total'
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type='checkbox'
                          id='Trop I T'
                          label='Trop I T'
                          value='Trop I T'
                          checked={diagnostics_is_top_i_t_2 === 'Trop I T' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <div className='input-group checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='12 - LECG'
                          label='12 - LECG'
                          value='12 - LECG'
                          checked={diagnostics_islecg === '12 - LECG' ? true : false}
                        />
                      </div>
                    </div>

                    <hr />

                    <div className='input-group checkbox'>
                      <label>XRAY</label>

                      <Form.Check
                        type='checkbox'
                        id='Chest'
                        label='Chest'
                        value='Chest'
                        checked={diagnostics_is_xray_chest === 'Chest' ? true : false}
                      />
                      <Form.Check
                        type='checkbox'
                        id='Abdomen'
                        label='Abdomen'
                        value='Abdomen'
                        checked={diagnostics_is_xray_abdomen === 'Abdomen' ? true : false}
                      />
                    </div>

                    <hr />

                    <div className='input-group two-sides'>
                      <div className='checkbox'>
                        <div className='selections'>
                          <Form.Check
                            type='checkbox'
                            id='Plain'
                            label='Plain'
                            value='Plain'
                            checked={diagnostics_is_xray_plain === 'Plain' ? true : false}
                          />
                          <Form.Check
                            type='checkbox'
                            id='Contrast'
                            label='Contrast'
                            value='Contrast'
                            checked={diagnostics_is_xray_contast === 'Contrast' ? true : false}
                          />
                          <Form.Check
                            type='checkbox'
                            id='Cranial'
                            label='Cranial'
                            value='Cranial'
                            checked={diagnostics_is_xray_cranial === 'Cranial' ? true : false}
                          />
                          <Form.Check
                            type='checkbox'
                            id='Cervical'
                            label='Cervical'
                            value='Cervical'
                            checked={diagnostics_is_xray_cervical === 'Cervical' ? true : false}
                          />
                          {/* <Form.Check
                        type='checkbox'
                        id='Abdomen'
                        label='Abdomen'
                        value='Abdomen'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_abdomen')}
                        checked={diagnostics_is_xray_abdomen === 'Abdomen' ? true : false}
                      /> */}
                        </div>
                      </div>

                      <hr />

                      <div className='checkbox'>
                        <div className='selections'>
                          <Form.Check
                            type='checkbox'
                            id='2D Echo'
                            label='2D Echo'
                            value='2D Echo'
                            checked={diagnostics_is_xray_2d_eco === '2D Echo' ? true : false}
                          />
                          <Form.Check
                            type='checkbox'
                            id='HBT'
                            label='HBT'
                            value='HBT'
                            checked={diagnostics_is_xray_hbt === 'HBT' ? true : false}
                          />
                          <Form.Check
                            type='checkbox'
                            id='Pelvic'
                            label='Pelvic'
                            value='Pelvic'
                            checked={diagnostics_is_xray_pelvic === 'Pelvic' ? true : false}
                          />
                          <Form.Check
                            type='checkbox'
                            id='Whole Abdomen'
                            label='Whole Abdomen'
                            value='Whole Abdomen'
                            checked={
                              diagnostics_is_xray_whole_abdomen === 'Whole Abdomen' ? true : false
                            }
                          />
                          <Form.Check
                            type='checkbox'
                            id='Transvaginal'
                            label='Transvaginal'
                            value='Transvaginal'
                            checked={
                              diagnostics_is_xray_transvaginal === 'Transvaginal' ? true : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>THERAPEUTICS</h3>

                <div className='box-cont'>
                  <div className='row-group w-50 mb-3'>
                    <div className='input-group medium'>
                      <label>Oxygen:</label>
                      <p className='w-100'>{therapeutics_oxygen}</p>
                    </div>

                    <div className='input-group medium'>
                      <div>
                        <label>LPM via</label>
                      </div>
                      <div>
                        <p>{therapeutics_lpm_via}</p>
                      </div>
                    </div>
                  </div>

                  <div className='row-group w-50 mb-3'>
                    <div className='input-group medium'>
                      <label>NPO</label>
                      <p>{therapeutics_npo}</p>
                    </div>

                    <div className='input-group medium'>
                      <label>Diet</label>
                      <p>{therapeutics_diet}</p>
                    </div>
                  </div>

                  <div className='row-group w-50 mb-3'>
                    <div className='input-group medium'>
                      <label>IVF</label>
                      <p>{therapeutics_ivf}</p>
                    </div>
                  </div>

                  <div className='row-group mb-3'>
                    <div className='input-group inline-header'>
                      <label>Medications</label>
                      <p>{therapeutics_medications}</p>
                    </div>

                    <div className='input-group inline-header'>
                      <label>Diagnosis</label>
                      <p>{therapeutics_diagnosis}</p>
                    </div>

                    <div className='input-group inline-header-small'>
                      <label>Time Given</label>
                      <p>{therapeutics_time_given}</p>
                    </div>

                    <div className='input-group inline-header-small'>
                      <label>Signature</label>
                      <p>{therapeutics_signature}</p>
                    </div>
                  </div>

                  <div className='row-group w-50 mb-3'>
                    <div className='input-group big'>
                      <label>MONITOR</label>
                      <p>{therapeutics_monitor}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>RESULTS</h3>

                <div className='box-cont'>
                  <div className='row-group mb-3' style={{ flexWrap: 'wrap' }}>
                    <div className='input-group small'>
                      <label>CBC</label>
                      <p>{results_cbc}</p>
                    </div>

                    <div className='input-group small'>
                      <label>CHEMISTRY</label>
                      <p>{results_chemistry}</p>
                    </div>

                    <div className='input-group small'>
                      <label>EKG</label>
                      <p>{results_ekg}</p>
                    </div>

                    <div className='input-group small'>
                      <label>RADIOGRAPHICS</label>
                      <p>{results_radiographics}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>REFERRALS</h3>

                <div className='box-cont'>
                  <div className='row-group mb-3' style={{ flexWrap: 'wrap' }}>
                    <div className='input-group small'>
                      <label>Referred by</label>
                      <p>{refferals_reffered_by}</p>
                    </div>

                    <div className='input-group small'>
                      <label>Time</label>
                      <p>{refferals_time}</p>
                    </div>

                    <div className='input-group small'>
                      <label>Service</label>
                      <p>{refferals_service}</p>
                    </div>

                    <div className='input-group small'>
                      <label>Reason for Referral</label>
                      <p>{refferals_reason_for_referral}</p>
                    </div>

                    <div className='input-group small'>
                      <label>Recieved by</label>
                      <p></p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>NURSE&apos;S NOTES</h3>

                <div className='box-cont' style={{ borderBottom: 'none' }}>
                  {nurse_notes.map((inputOtherField, key, { length }) => {
                    return (
                      <Row className='row-group mb-3' key={inputOtherField.id}>
                        <Col className='input-group mb-3 max-width'>
                          <div>
                            <p>Date/Time</p>
                            <p className='max-width'>{inputOtherField.date}</p>
                          </div>
                        </Col>
                        <Col className='input-group mb-3 max-width'>
                          <div>
                            <p>BP</p>
                            <p className='max-width'>{inputOtherField.bp}</p>
                          </div>
                        </Col>
                        <Col className='input-group mb-3 max-width'>
                          <div>
                            <p>HR</p>
                            <p className='max-width' value={inputOtherField.hr}>
                              {inputOtherField.hr}
                            </p>
                          </div>
                        </Col>
                        <Col className='input-group mb-3 max-width'>
                          <div>
                            <p>RR</p>
                            <p className='max-width'>{inputOtherField.rr}</p>
                          </div>
                        </Col>
                        <Col className='input-group mb-3 max-width'>
                          <div>
                            <p>Time</p>
                            <p className='max-width'>{inputOtherField.time}</p>
                          </div>
                        </Col>
                        <Col className='input-group mb-3 max-width'>
                          <div>
                            <p>WT</p>
                            <p className='max-width'>{inputOtherField.wt}</p>
                          </div>
                        </Col>
                        <Col className='input-group mb-3 max-width'>
                          <div>
                            <p>CBG</p>
                            <p className='max-width'>{inputOtherField.cbg}</p>
                          </div>
                        </Col>
                        <Col className='input-group mb-3 '>
                          <Row>
                            <Col>
                              <p>Signature</p>
                              <p className='no-border'>{inputOtherField.signature}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='blue-header-container p-0'>
                <h3>Patient Status</h3>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <div className='row-group mb-3'>
                      <div className='input-group small'>
                        <label>Date</label>
                        <p>{date}</p>
                      </div>
                    </div>

                    <div className='row-group mb-3'>
                      <div className='input-group small'>
                        <label>Time</label>
                        <p>{time}</p>
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Treated and Discharge'
                          label='Treated and Discharge'
                          value='Treated and Discharge'
                        />
                        <Form.Check
                          type='checkbox'
                          id='Absconded'
                          label='Absconded'
                          value='Absconded'
                        />
                        <Form.Check type='checkbox' id='Admit' label='Admit' value='Admit' />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <div className='checkbox'>
                      <div className='selections'>
                        <Form.Check
                          type='checkbox'
                          id='Home Againts Medical Advice'
                          label='Home Againts Medical Advice'
                          value='Home Againts Medical Advice'
                        />
                        <Form.Check
                          type='checkbox'
                          id='ER Death'
                          label='ER Death'
                          value='ER Death'
                        />
                        <Form.Check
                          type='checkbox'
                          id='Dead On Arrival ( DOA )'
                          label='Dead On Arrival ( DOA )'
                          value='Dead On Arrival ( DOA )'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <div className='checkbox'>
                      <div className='selections'>
                        <div className='d-flex gap-10'>
                          <Form.Check
                            type='checkbox'
                            id='Transfer to Hospital'
                            label='Transfer to Hospital'
                            value='Transfer to Hospital'
                          />

                          <div className='input-group max-width'>
                            <p>{hospital_name}</p>
                          </div>
                        </div>
                        <Form.Check
                          type='checkbox'
                          id='Self Conduction'
                          label='Self Conduction'
                          value='Self Conduction'
                        />

                        <div className='d-flex gap-10'>
                          <Form.Check
                            type='checkbox'
                            id='Ambulance'
                            label='Ambulance'
                            value='Ambulance'
                          />
                          <Form.Check
                            type='checkbox'
                            id='Private'
                            label='Private'
                            value='Private'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <div className='row-group mb-3'>
                      <div className='input-group inline-header align-items-start big'>
                        <label>Discharge Diagnosis</label>
                        <p>{discharge_diagnosis}</p>
                      </div>
                    </div>

                    <div className='row-group mb-3'>
                      <div className='input-group inline-header align-items-start big'>
                        <label>Medications</label>
                        <p>{medications_2}</p>
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <div className='row-group mb-3'>
                      <div className='input-group inline-header align-items-start big'>
                        <label>Discharge Plans</label>
                        <p>{discharge_plans}</p>
                      </div>
                    </div>

                    <div className='row-group mb-3'>
                      <div className='input-group inline-header align-items-start big'>
                        <label>Special Instructions</label>
                        <p>{special_instructions}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <h6>FOLLOW - UP</h6>
                  </div>

                  <div className='info border-left'>
                    <h6>ATTENDING PHYSICIAN</h6>
                  </div>
                </div>

                <div className='box-cont d-flex p-0'>
                  <div className='info border-left'>
                    <div className='row-group mb-3'>
                      <div className='input-group big'>
                        <label>Clinic</label>
                        <p>{follow_up_clinic}</p>
                      </div>
                    </div>
                    <div className='row-group mb-3'>
                      <div className='input-group small'>
                        <label>Date</label>
                        <p>{follow_up_date}</p>
                      </div>
                    </div>
                    <div className='row-group mb-3'>
                      <div className='input-group small'>
                        <label>Time</label>
                        <p>{follow_up_time}</p>
                      </div>
                    </div>
                  </div>

                  <div className='info border-left'>
                    <div className='row-group mb-3'>
                      <div className='input-group big'>
                        <p>{attending_physician_name}</p>
                      </div>
                    </div>

                    <div className='row-group mb-3'>
                      <div className='input-group big'>
                        <label>Date</label>
                        <p>{attending_physician_date}</p>
                      </div>

                      <div className='input-group small'>
                        <label>Time</label>
                        <p>{attending_physician_time}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </tbody>
      </Table>
    </div>
  );
});

PrintContent.displayName = 'PrintContent';

export default PrintContent;
