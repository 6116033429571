/* eslint-disable camelcase */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import PatientSelection from './PatientSelection';
import ConditionalRender from 'components/ConditionalRender';
import { selectActivePatients } from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import { PrintSubpoenaAdTestificandum } from './PrintComponents/PrintSubpoenaAdTestificandum';
import { createAdTestificandum, getMedicalFormsbyID } from 'reducers/thunks/medicalRecordThunks';

// main component
const SubpoenaAdTestificandum = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();

  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [isAdd, setIsAdd] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [formData, setFormData] = useState({
    vs: '',
    HON: '',
    date: '',
    dear: '',
    year: '',
    dated: '',
    address: '',
    case_no: '',
    hearing_on: '',
    since_year: '',
    dr_full_name: '',
    presiding_judge: '',
    name_of_hospital: '',
    patient_full_name: '',
    chief_of_hospital: '',
    patientId: Math.random(),
    regional_court_branch: '',
    type: 'subpoena_ad_testificandum',
  });

  const isAdding = isAdd === true;
  const isEditing = isEdit === true;
  const isNotEditing = isEdit === false;

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    console.log('patient list: ', formData);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  // For Add
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createAdTestificandum({ ...formData, method: 'post' }));
      return;
    }

    toast.warning('Fill up the  fields');
    setValidated(true);
  };

  // For Edit
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createAdTestificandum({
        ...formData,
        method: 'put',
        type: 'cert_of_dismembered_body_part',
      }),
    );

    setIsEdit(false);
    return;
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const textBox = (key) => {
    return (
      <FormControl
        disabled={!isEdit}
        value={formData[key]}
        onChange={handleChange(key)}
        type={`${key === 'age' ? 'number' : 'text'}`}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
      />
    );
  };

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    const {
      isFailed,
      isSuccess,
      patientID,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    } = logs;

    useCreateRecordResponse({
      trigger,
      isFailed,
      dispatch,
      isSuccess,
      setIsEdit,
      patientID,
      setTrigger,
      setShowScreen,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    });

    if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_subpoena_ad_testificandum;
      setFormData(data);
    }
  }, [logs]);

  useEffect(() => {
    dispatch(getPatients());
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
    }
  }, [isEdit]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintSubpoenaAdTestificandum ref={printRef} formData={formData} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.name_of_hospital}
                onChange={handleChange('name_of_hospital')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Name of Healthcare Facility</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.address}
                onChange={handleChange('address')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col className='second-row align-items-start'>
            <Form.Group className='input-group mb-3'>
              <Form.Label>Date</Form.Label>
              <FormControl
                type='date'
                placeholder='Enter Here'
                value={formData?.date}
                onChange={handleChange('date')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group'>
              <Form.Label>HON.</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.HON}
                onChange={handleChange('HON')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='left-input-group'>
              <p>Presiding Judge</p>

              <div className='group'>
                <Form.Label>Regional Court, Branch</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.regional_court_branch}
                  onChange={handleChange('regional_court_branch')}
                  disabled={!isEdit}
                />
              </div>

              <p>National Capital Judicial Region, Manila</p>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='letter-cont'>
            <h2>Dear</h2>
            <FormControl
              placeholder='Enter Here'
              value={formData?.dear}
              onChange={handleChange('dear')}
              disabled={!isEdit}
            />
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col>
            <p className='mb-4'>
              May we write in connection with the Honorable Court&apos;s Subpoena ad testification
              addressed to the undersigned and dated{' '}
              <FormControl
                type='date'
                placeholder='Enter Here'
                value={formData?.dated}
                onChange={handleChange('dated')}
                disabled={!isEdit}
              />
              , and directing me to appear ad testify in the case people vs.{' '}
              <FormControl
                placeholder='Enter Here'
                value={formData?.vs}
                onChange={handleChange('vs')}
                disabled={!isEdit}
              />{' '}
              Crim. Case No.{' '}
              <FormControl
                placeholder='Enter Here'
                value={formData?.case_no}
                onChange={handleChange('case_no')}
                disabled={!isEdit}
              />{' '}
              which is scheduled for hearing on{' '}
              <FormControl
                placeholder='Enter Here'
                value={formData?.hearing_on}
                onChange={handleChange('hearing_on')}
                disabled={!isEdit}
              />
              . May I apologized in advance for the reason that Dr.
              <FormControl
                placeholder='Enter Here'
                value={formData?.dr_full_name}
                onChange={handleChange('dr_full_name')}
                disabled={!isEdit}
              />
              , the actual attending physician of patient{' '}
              <FormControl
                required
                placeholder='Enter Here'
                value={formData?.patient_full_name}
                onClick={handleShowPatientSelection}
                disabled={!isEdit}
              />{' '}
              is no longer with our hospital / institution since{' '}
              <FormControl
                placeholder='Enter Here'
                value={formData?.since_year}
                onChange={handleChange('since_year')}
                disabled={!isEdit}
              />{' '}
              and we have no knowledge of where he is currently residing or practicing her
              profession. May I explain that I have no personal knowledge in the manner of treatment
              of her patient and it is only the patient&apos;s attending physician who can
              intelligently explain the entries in the medical record which is the subject of the
              Honorable Court&apos;s Subpoena. May statements before the court would only be
              bordering on speculations or hearsays.
            </p>

            <p className='mb-5'>
              Again our apologies for the situation and we express our willingness to help in some
              other ways that we can in connection the case.
            </p>

            <div className='signature mb-4 align-items-start'>
              <Form.Group className='attending-physician'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.chief_of_hospital}
                  onChange={handleChange('chief_of_hospital')}
                  className='text-center'
                  disabled={!isEdit}
                />
                <Form.Label>Chief of Hospital</Form.Label>
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>
                          PRINT CERTIFICATE
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          className='save'
                          disabled={trigger.trigger}
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>

      <PatientSelection
        fields={formData}
        setFields={setFormData}
        patientSelection={patientSelection}
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
      />
    </>
  );
};

export default SubpoenaAdTestificandum;
