/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */ 
/* eslint-disable comma-spacing */
/* eslint-disable no-unneeded-ternary  */
/* eslint-disable comma-dangle */
//  import core & vendor packages below
import React from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

// import assets below
import './index.scss';

// main component
const headerTitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '16px', 
};
const headerSubtitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '14px', 
  marginTop: '5px', 
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;
  const { 
    accident_date_time, address,amputee_age,
    amputee_brgy,
    amputee_citizenship,
    amputee_city_municipality,
    amputee_fname,
    amputee_house_no,
    amputee_lname,amputee_mname, amputee_occupation,
    amputee_sex, amputee_street,
    cert_no,
    certification_address,
    certification_date,
    certification_name_in_print,
    certification_signature,
    certification_title_or_position,
    indication, informat_address,
    informat_name_in_print,
    informat_relation_to_amputee,
    informat_signature,
    laterality_A,
    laterality_B,
    laterality_C, createdAt, id,
    manner_of_disposal,
    manner_type,
    medicalRecordId,
    name_and_address_of_cemetery_or_cremator,
    name_of_hospital,
    operation_date_time,
    part_of_body_A,
    part_of_body_B,
    part_of_body_C,
    permit_date_issued,
    permit_issued_by,
    permit_number,
    place_of_amputation_address_in_case_of_accident,
    place_of_amputation_clinic,
    place_of_amputation_hospital,
    place_of_amputation_institution,
    prepared_by_address,
    prepared_by_date,
    prepared_by_name_in_print,
    prepared_by_signature,
    prepared_by_title_or_position,
    remarks, updatedAt
  } = data;
  console.log(data);
  return (
    <div className='print_body' ref={ref}> 
      <header>
          <div className='header_logo'>
            <img src={REACT_APP_LOGO} alt='print_logo' />
          </div>
          {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
          <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
          <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
     <Table bordered>
      <thead>
        <tr>
          <th>{viewFormType}</th>
        </tr>
      </thead>
      <tbody>
        <Container>
        <Row className='mb-4'>
          <Col className='first-row'>
            <div className='input-group'>
              <p className='text-center'>{name_of_hospital}</p>
              <label>Name of Healthcare Facility</label>
            </div>

            <div className='input-group'>
              <p className='text-center'>{address}</p>
              <label>Address</label>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='second-row'>
            <div className='input-group'>
              <label>Certificate No.</label>
              <p>{cert_no}</p>
            </div>
          </Col>
        </Row>
    
        <Row>
          <Col className='third-row'>
            <h2>{viewFormType}</h2>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column'>
              <h5>1. PART/S OF THE BODY</h5>
              <div className='d-flex justify-content-start w-100'>
                <label className='me-1'>A.</label>
                <p className='text-break'>{part_of_body_A}</p>
              </div>

              <div className='d-flex justify-content-start w-100'>
                <label className='me-1'>B.</label>
                <p className='text-break'>{part_of_body_B}</p>
              </div>

              <div className='d-flex justify-content-start w-100'>
                <label className='me-1'>C.</label>
                <p className='text-break'>{part_of_body_C}</p>
              </div>
            </div>
            <div className='second-column'>
              <h5>2. LATERALITY</h5>

              <div className='checkbox'>
                <label>A.</label>
                <div className='selections'>
                  <div>
                    <input
                      type='checkbox'
                      checked={laterality_A?.toLowerCase() === 'left' ? true : false}
                      className='me-1'
                    />
                    <label className='text-uppercase'>left</label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={laterality_A?.toLowerCase() === 'right' ? true : false}
                      className='me-1'
                    />
                    <label className='text-uppercase'>right</label>
                  </div>
                </div>
              </div>

              <div className='checkbox'>
                <label>B.</label>
                <div className='selections'>
                  <div>
                    <input
                      type='checkbox'
                      checked={laterality_B?.toLowerCase() === 'left' ? true : false}
                      className='me-1'
                    />
                    <label className='text-uppercase'>left</label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={laterality_B?.toLowerCase() === 'right' ? true : false}
                      className='me-1'
                    />
                    <label className='text-uppercase'>right</label>
                  </div>
                </div>
              </div>

              <div className='checkbox'>
                <label>C.</label>
                <div className='selections'>
                  <div>
                    <input
                      type='checkbox'
                      checked={laterality_C?.toLowerCase() === 'left' ? true : false}
                      className='me-1'
                    />
                    <label className='text-uppercase'>left</label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={laterality_C?.toLowerCase() === 'right' ? true : false}
                      className='me-1'
                    />
                    <label className='text-uppercase'>right</label>
                  </div>
                </div>
              </div>
            </div>
            <div className='third-column'>
              <h5>3. MANNER / CAUSE OF AMPUTATION</h5>

              <div className='row-cont'>
                <div className='date-time input-group'>
                  <label>Accident, specify ( Date / Time ):</label>
                  <p>{accident_date_time}</p>
                </div>

                <div className='type input-group'>
                  <label>Type:</label>
                  <p>{manner_type}</p>
                </div>
              </div>

              <div className='row-cont'>
                <div className='date-time input-group'>
                  <label>Operation ( Date / Time ):</label>
                  <p>{operation_date_time}</p>
                </div>

                <div className='type input-group'>
                  <label>Indication:</label>
                  <p>{indication}</p>
                </div>
              </div>

              <div className='row-cont'>
                <div className='date-time input-group'>
                  <label>Others, specify:</label>
                  <p>{remarks}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column align-items-start'>
              <h5>PLACE OF APUTATION</h5>

              <div className='one-row'>
                <div className='input-group'>
                  <label>Name of Hospital</label>
                  <p>{place_of_amputation_hospital}</p>
                </div>

                <div className='input-group'>
                  <label>Clinic</label>
                  <p>{place_of_amputation_clinic}</p>
                </div>

                <div className='input-group'>
                  <label>Institution</label>
                  <p>{place_of_amputation_institution}</p>
                </div>
              </div>

              <div className='one-row'>
                <div className='input-group'>
                  <label>Address in case of accident</label>
                  <p>{place_of_amputation_address_in_case_of_accident}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column align-items-start'>
              <h5>AMPUTEE</h5>

              <div className='one-row'>
                <div className='input-group'>
                  <label>First Name</label>
                  <p>{amputee_fname}</p>
                </div>

                <div className='input-group'>
                  <label>Middle Name</label>
                  <p>{amputee_mname}</p>
                </div>

                <div className='input-group'>
                  <label>Last Name</label>
                  <p>{amputee_lname}</p>
                </div>
              </div>

              <div className='one-row'>
                <div className='input-group'>
                  <label>Sex</label> 
                  <p>{amputee_sex}</p>
                </div>

                <div className='input-group age'>
                  <label>Age</label>
                  <p>{amputee_age}</p>
                </div>

                <div className='input-group citizenship'>
                  <label>Citizenship</label>
                  <p>{amputee_citizenship}</p>
                </div>

                <div className='input-group occupation'>
                  <label>Occupation</label>
                  <p>{amputee_occupation}</p>
                </div>
              </div>

              <div className='one-row'>
                <div className='input-group'>
                  <label>House No.,</label>
                  <p>{amputee_house_no}</p>
                </div>

                <div className='input-group'>
                  <label>Street</label>
                  <p>{amputee_street}</p>
                </div>

                <div className='input-group'>
                  <label>Barangay</label>
                  <p>{amputee_brgy}</p>
                </div>

                <div className='input-group'>
                  <label>City/Municipality</label>
                  <p>{amputee_city_municipality}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column large-width align-items-start'>
              <h5>
                CERTIFICATION
                <span>
                  I hereby certify that the foregoing particulars are correct as near as same be
                  ascertained.
                </span>
              </h5>

              <div className='inline-label'>
                <label>Signature</label>
                <p>{certification_signature}</p>
              </div>

              <div className='inline-label'>
                <label>Name in Print</label>
                <p>{certification_name_in_print}</p>
              </div>

              <div className='inline-label'>
                <label>Title or Position</label>
                <p>{certification_title_or_position}</p>
              </div>

              <div className='inline-label'>
                <label>Address</label>
                <p>{certification_address}</p>
              </div>

              <div className='inline-label'>
                <label>Date</label>
                <p>{certification_date}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container gap-10'>
            <div className='first-column align-items-start'>
              <h5>INFORMAT</h5>

              <div className='input-group'>
                <label>Signature</label>
                <p>{informat_signature}</p>
              </div>

              <div className='input-group'>
                <label>Name in Print</label>
                <p>{informat_name_in_print}</p>
              </div>

              <div className='input-group'>
                <label>Relationship to Amputee</label>
                <p>{informat_relation_to_amputee}</p>
              </div>

              <div className='input-group'>
                <label>Address</label>
                <p>{informat_address}</p>
              </div>
            </div>

            <div className='second-column align-items-start'>
              <h5>PREPARED BY</h5>

              <div className='input-group'>
                <label>Signature</label>
                <p>{prepared_by_signature}</p>
              </div>

              <div className='input-group'>
                <label>Name in Print</label>
                <p>{prepared_by_name_in_print}</p>
              </div>

              <div className='input-group'>
                <label>Title or Position</label>
                <p>{prepared_by_title_or_position}</p>
              </div>

              <div className='input-group'>
                <label>Address</label>
                <p>{prepared_by_address}</p>
              </div>

              <div className='input-group'>
                <label>Date</label>
                <p>{prepared_by_date}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='bordered-container gap-10'>
            <div className='first-column align-items-start'>
              <h5>MANNER OF DISPOSAL</h5>

              <div className='checkbox'>
                <label>A.</label>

                <div className='selections'>
                  <div>
                    <input
                      type='checkbox'
                      checked={manner_of_disposal?.toLowerCase() === 'burial' ? true : false}
                      className='me-1'
                    />
                    <label>Burial</label>
                  </div>
                </div>
              </div>

              <div className='checkbox'>
                <label>B.</label>
                <div className='selections'>
                  <div>
                    <input
                      type='checkbox'
                      checked={
                        manner_of_disposal?.toLowerCase() === 'cremation' ? true : false
                      }
                      className='me-1'
                    />
                    <label>Cremation</label>
                    </div>
                  </div>
              </div>

              <div className='checkbox'>
                <label>C.</label>

                <div className='selections'>
                  <div>
                    <input
                      type='checkbox'
                      checked={manner_of_disposal?.toLowerCase() === 'others' ? true : false}
                      className='me-1'
                    />
                    <label>OTHERS (Specify)</label>
                  </div>
                </div>
              </div>
            </div>

            <div className='second-column align-items-start'>
              <h5>BURIAL / CREMATION PERMIT</h5>

              <div className='input-group'>
                <label>Number</label>
                <p>{permit_number}</p>
              </div>

              <div className='input-group'>
                <label>Date Issued</label>
                <p>{permit_date_issued}</p>
              </div>

              <div className='input-group'>
                <label>Issued By</label>
                <p>{permit_issued_by}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col className='name'>
            <div className='input-group'>
              <label>Name and Address of Cemetery or Cremator</label>
              <p>{name_and_address_of_cemetery_or_cremator}</p>
            </div>
          </Col>
        </Row>
      </Container>
      </tbody>
      </Table>
    </div>
  );
}); 

PrintContent.displayName = 'PrintContent';

export default PrintContent; 
