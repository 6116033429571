/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import Moment from 'moment';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Form, FormControl } from 'react-bootstrap';
import { DropdownDate, DropdownComponent } from 'react-dropdown-date';

// import components below
import {
  createCertofFetalDeath,
  getMedicalFormsbyID,
  selectActivePatients,
} from 'reducers/medicalRecordReducer';
import PatientSelection from './PatientSelection';
import { getPatients } from 'reducers/thunks/patientThunks';
import { selectPatientData } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';

// import assets below
import './CertificateOfFetalDeath.scss';

// main component
const CertificateOfFetalDeath = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();
  const logs = useSelector(selectActivePatients);
  const [page, setPage] = useState(1);
  const [isAdd, setIsAdd] = useState(false);
  const [datedeliver, setDatedelivery] = useState({ selectedDate: '2012-11-05', date: null });
  const [datemarriage, setDatemarriage] = useState({ selectedDate: '2012-11-05', date: null });
  const [formData, setFormData] = useState({
    province: '',
    city: '',
    register_no: '',
    fname: '',
    mname: '',
    lname: '',
    sex: '',
    day_of_delivery: '',
    month_of_delivery: '',
    year_of_delivery: '',
    place_of_delivery_hospital: '',
    place_of_delivery_house_no_st_brgy: '',
    place_of_delivery_city: '',
    place_of_delivery_province: '',
    mother_fname: '',
    mother_mname: '',
    mother_lname: '',
    mother_citizenship: '',
    mother_religion: '',
    mother_occupation: '',
    mother_age_at_time_of_delivery: '',
    total_no_of_children_born_alive: '',
    no_of_children_still_living: '',
    no_of_children_born_alive_but_now_dead: '',
    mother_house_no_st_brgy: '',
    mother_city: '',
    mother_province: '',
    mother_country: '',
    father_fname: '',
    father_mname: '',
    father_lname: '',
    father_citizenship: '',
    father_religion: '',
    father_occupation: '',
    father_age_at_time_of_delivery: '',
    marriage_month: '',
    marriage_day: '',
    marriage_year: '',
    marriage_place_city: '',
    marriage_place_province: '',
    marriage_place_country: '',
    main_disease: '',
    other_diseases: '',
    main_maternal_diseases: '',
    other_maternal_diseases: '',
    other_relevant_circumstances: '',
    significant_conditions_contributing_to_death: '',
    fetus_died: '',
    length_of_pregnancy_in_weeks: '',
    attendant: '',
    cert_of_death_have_attended: '',
    cert_of_death_have_not_attended: '',
    cert_of_death_signature: '',
    cert_of_death_name_in_print: '',
    cert_of_death_title_or_position: '',
    cert_of_death_address: '',
    cert_of_death_date: '',
    cert_of_death_reviewed_by: '',
    cert_of_death_signature_over_printed_name: '',
    cert_of_death_reviewed_date: '',
    corpse_disposal: '',
    burial_cremation_permit_no: '',
    burial_cremation_permit_date_issued: '',
    transfer_permit_no: '',
    transfer_permit_date_issued: '',
    name_and_address_of_cemetery_or_crematory: '',
    cert_of_informat_signature: '',
    cert_of_informat_name_in_print: '',
    cert_of_informat_title_or_position: '',
    cert_of_informat_address: '',
    cert_of_informat_date: '',
    prepared_by_signature: '',
    prepared_by_name_in_print: '',
    prepared_by_title_or_position: '',
    prepared_by_address: '',
    prepared_by_date: '',
    recieved_by_signature: '',
    recieved_by_name_in_print: '',
    recieved_by_title_or_position: '',
    recieved_by_address: '',
    recieved_by_date: '',
    civil_registrar_signature: '',
    civil_registrar_name_in_print: '',
    civil_registrar_title_or_position: '',
    civil_registrar_address: '',
    civil_registrar_date: '',
    remarks: '',
    cause_of_death: '',
    postmortem_signature: '',
    postmortem_name_in_print: '',
    postmortem_date: '',
    postmortem_title_or_position: '',
    postmortem_address: '',
    name: '',
    embalmer_signature: '',
    embalmer_name_in_print: '',
    embalmer_title_date: '',
    embalmer_title_designation: '',
    embalmer_license_no: '',
    embalmer_license_issued_on: '',
    embalmer_license_issued_at: '',
    embalmer_license_expiry_date: '',
    affidavit_name: '',
    affidavit_civil_status: '',
    affidavit_address: '',
    affidavit_deceased_name: '',
    affidavit_died_on: '',
    affidavit_died_in: '',
    affidavit_burried_or_cremated_in: '',
    affidavit_burried_or_cremated_on: '',
    enable_attended: '',
    affidavit_was_attended: '',
    affidavit_was_not_attended: '',
    affidavit_was_attented_by: '',
    affidavit_cause_of_death: '',
    affidavit_reason_for_late_registration_of_death: '',
    affidavit_day: '',
    affidavit_month: '',
    affidavit_year: '',
    affidavit_address_1: '',
    affidavit_signature_over_printed_name_of_affiant: '',
    affidavit_subscribed_and_sworn_day: '',
    affidavit_subscribed_and_sworn_month: '',
    affidavit_subscribed_and_sworn_year: '',
    affidavit_subscribed_and_sworn_address: '',
    affidavit_tax_certificate: '',
    affidavit_tax_certificate_issued_on: '',
    affidavit_tax_certificate_issued_at: '',
    affidavit_signature_of_administering_officer: '',
    affidavit_name_in_print: '',
    affidavit_position: '',
    affidavit_address_2: '',
    type: 'cert_of_fetal_death',
    patientId: Math.random(),
  });
  const [validated, setValidated] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const isEditing = isEdit === true;
  const isAdding = isAdd === true;
  const isPageOne = page === 1;
  const isPageTwo = page === 2;

  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const patientSelection = useSelector(selectPatientData);

  // For Add
  const addRecord = () => {
    return;
  };

  // For Edit
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createCertofFetalDeath({
        ...formData,
        method: 'put',
        type: 'cert_of_fetal_death',
      }),
    );
    setIsEdit(false);
    return;
  };

  const handleChange = (key) => (e) => {
    const { type, value } = e.target;

    if (type === 'radio' && key === 'enable_attended') {
      console.log('aywaaa', key);
      console.log('aywaaa', value);

      setFormData((prev) => {
        return { ...prev, [key]: value };
      });

      return;
    }

    setFormData((prev) => {
      return { ...prev, [key]: e.target.value };
    });

    return;
  };

  const textBox = (key) => {
    return (
      <FormControl
        type={`${key === 'age' ? 'number' : 'text'}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (!form.checkValidity() === false) {
      setTrigger(true);
      setIsEdit(false);
      dispatch(createCertofFetalDeath({ ...formData }));
      toast.success('Successfully created record.');
      setIsAdd(false);
      return;
    }
    toast.warning('Fill up the required fields');
    setValidated(true);
  };

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    dispatch(getPatients());

    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }

    // for refactor: externalize below axios code in to thunk
    const recordId = viewForm?.data?.id;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        console.log('record info: ', response.data);
        setFormData({
          ...response.data?.data?.mr_cert_of_fetal_death,
          patientId: response.data?.data?.mr_outpatient_record?.id,
          type: 'cert_of_fetal_death',
        });
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
    } = logs;
    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_cert_of_fetal_death;
      setFormData(data);
    } */
  }, [logs]);

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  return (
    <>
      <Form
        className='certificate-fedal-death'
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <ConditionalRender
          condition={isPageOne}
          renderIf={
            <div className='mb-4 black-border-container'>
              <div className='black-row'>
                <div className='header'>
                  <p className='left-header'>
                    OCRG Form No. 101 <span>( Revised August 2021 )</span>
                  </p>

                  <div className='center-header'>
                    <p>Republic of the Philippines</p>
                    <p>OFFICE OF THE CIVIL REGISTRAT GENERAL</p>
                    <h2>CERTIFICATE OF FETAL DEATH</h2>
                  </div>

                  <p className='right-header'>
                    (To be accomplished in triplicate using black ink )
                  </p>
                </div>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={8} className='block-container right-border'>
                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Province</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.province}
                        onChange={handleChange('province')}
                      />
                    </Form.Group>

                    <Form.Group className='input-group inline-label p-0'>
                      <Form.Label>City / Municipality</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        value={formData?.city}
                        placeholder='Enter Here'
                        onChange={handleChange('city')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container'>
                    <Form.Group className='input-group p-0'>
                      <Form.Label>Register No.</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.register_no}
                        onChange={handleChange('register_no')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <div className='block-with-sidebar'>
                  <div className='side-header right-border'>
                    <h3>FETUS</h3>
                  </div>
                  <div className='main-content'>
                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container'>
                          <h4 className='mb-2'>1. NAME</h4>

                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( First )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.fname}
                                onChange={handleChange('fname')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Middle )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mname}
                                onChange={handleChange('mname')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Last )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.lname}
                                onChange={handleChange('lname')}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>2. SEX</h4>

                          <div className='selections inline'>
                            <Form.Check
                              type='radio'
                              name='sex'
                              label='Male'
                              value='Male'
                              disabled={!isEdit}
                              onChange={handleChange('sex')}
                              checked={formData?.sex === 'Male'}
                            />
                            <Form.Check
                              type='radio'
                              name='sex'
                              label='Female'
                              value='Female'
                              disabled={!isEdit}
                              onChange={handleChange('sex')}
                              checked={formData?.sex === 'Female'}
                            />
                            <Form.Check
                              type='radio'
                              name='sex'
                              label='Undertermined'
                              value='Undertermined'
                              disabled={!isEdit}
                              onChange={handleChange('sex')}
                              checked={formData?.sex === 'Undertermined'}
                            />
                          </div>
                        </Col>
                        <Col className='block-container'>
                          <h4 className='mb-4'>3. DATE OF DELIVERY</h4>

                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Day )</Form.Label>
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Month )</Form.Label>
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Year )</Form.Label>
                            </Form.Group>
                          </div>
                          <div className='inline-group'>
                            <DropdownDate
                              classes={{
                                dateContainer: 'date-container',
                                yearContainer: 'year-container',
                                monthContainer: 'month-container',
                                dayContainer: 'day-container',
                                year: 'dropdown-year',
                                month: 'dropdown-month',
                                day: 'dropdown-day',
                              }}
                              order={[
                                DropdownComponent.day,
                                DropdownComponent.month,
                                DropdownComponent.year,
                              ]}
                              selectedDate={datedeliver.selectedDate}
                              options={{
                                yearReverse: true,
                                monthShort: true,
                                monthCaps: true,
                              }}
                              onDateChange={(date) => {
                                if (isEdit === false) return;

                                const dateconfig = Moment(date).format('YYYY-MM-DD');
                                const date_separete = dateconfig.split('-');
                                setFormData((prev) => {
                                  return {
                                    ...prev,
                                    day_of_delivery: date_separete[2],
                                    month_of_delivery: date_separete[1],
                                    year_of_delivery: date_separete[0],
                                  };
                                });
                                setDatedelivery((prev) => {
                                  return {
                                    ...prev,
                                    selectedDate: Moment(date).format('YYYY-MM-DD'),
                                  };
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row last-row'>
                      <Row className='m-0'>
                        <Col className='block-container'>
                          <h4 className='mb-2'>4. PLACE OF DELIVERY</h4>

                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>
                                ( Name of Hospital / Clinic / Institution )
                              </Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.place_of_delivery_hospital}
                                onChange={handleChange('place_of_delivery_hospital')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( House No., St., Barangay )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.place_of_delivery_house_no_st_brgy}
                                onChange={handleChange('place_of_delivery_house_no_st_brgy')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( City / Municipality )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.place_of_delivery_city}
                                onChange={handleChange('place_of_delivery_city')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Province )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.place_of_delivery_province}
                                onChange={handleChange('place_of_delivery_province')}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              <div className='black-row'>
                <div className='block-with-sidebar'>
                  <div className='side-header right-border'>
                    <h3>MOTHER</h3>
                  </div>

                  <div className='main-content'>
                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container'>
                          <h4 className='mb-2'>6. MAIDEN NAME</h4>

                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( First )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mother_fname}
                                // onChange={handleChange('mother_fname')}
                                onClick={handleShowPatientSelection}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Middle )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mother_mname}
                                // onChange={handleChange('mother_mname')}
                                onClick={handleShowPatientSelection}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Last )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mother_lname}
                                // onChange={handleChange('mother_lname')}
                                onClick={handleShowPatientSelection}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>7. CITIZENSHIP</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.mother_citizenship}
                              onChange={handleChange('mother_citizenship')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>8. RELIGION/RELIGIOUS SECT</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.mother_religion}
                              onChange={handleChange('mother_religion')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>9. OCCUPATION</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.mother_occupation}
                              onChange={handleChange('mother_occupation')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container'>
                          <h4 className='mb-2'>
                            10. AGE at the time of this delivery ( completed years )
                          </h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.mother_age_at_time_of_delivery}
                              onChange={handleChange('mother_age_at_time_of_delivery')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>11a. Total Number of children born alive</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.total_no_of_children_born_alive}
                              onChange={handleChange('total_no_of_children_born_alive')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>11b. No. of children still living</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.no_of_children_still_living}
                              onChange={handleChange('no_of_children_still_living')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container'>
                          <h4 className='mb-2'>11c. No. of children born alive but are now dead</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.no_of_children_born_alive_but_now_dead}
                              onChange={handleChange('no_of_children_born_alive_but_now_dead')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row last-row'>
                      <Row className='m-0'>
                        <Col className='block-container'>
                          <h4 className='mb-2'>12. RESIDENCE</h4>

                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( House No., St., Barangay )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mother_house_no_st_brgy}
                                onChange={handleChange('mother_house_no_st_brgy')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( City / Municipality )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mother_city}
                                onChange={handleChange('mother_city')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Province )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mother_province}
                                onChange={handleChange('mother_province')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Country )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.mother_country}
                                onChange={handleChange('mother_country')}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              <div className='black-row'>
                <div className='block-with-sidebar'>
                  <div className='side-header right-border'>
                    <h3>FATHER</h3>
                  </div>

                  <div className='main-content'>
                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container'>
                          <h4 className='mb-2'>13. NAME</h4>

                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( First )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.father_fname}
                                onChange={handleChange('father_fname')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Middle )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.father_mname}
                                onChange={handleChange('father_mname')}
                              />
                            </Form.Group>

                            <Form.Group className='input-group p-0'>
                              <Form.Label className=''>( Last )</Form.Label>
                              <FormControl
                                disabled={!isEdit}
                                placeholder='Enter Here'
                                value={formData?.father_lname}
                                onChange={handleChange('father_lname')}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row last-row'>
                      <Row className='m-0'>
                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>7. CITIZENSHIP</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.father_citizenship}
                              onChange={handleChange('father_citizenship')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>8. RELIGION/RELIGIOUS SECT</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.father_religion}
                              onChange={handleChange('father_religion')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container right-border'>
                          <h4 className='mb-2'>9. OCCUPATION</h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.father_occupation}
                              onChange={handleChange('father_occupation')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container'>
                          <h4 className='mb-2'>
                            10. AGE at the time of this delivery ( completed years )
                          </h4>

                          <Form.Group className='input-group p-0'>
                            <FormControl
                              disabled={!isEdit}
                              placeholder='Enter Here'
                              value={formData?.father_age_at_time_of_delivery}
                              onChange={handleChange('father_age_at_time_of_delivery')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container small marriage'>
                    <h3>MARRIAGE OF PARENTS</h3>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={4} className='block-container right-border'>
                    <h4 className='mb-4'>18a. DATE</h4>

                    <div className='inline-group text-center'>
                      <Form.Group className='input-group p-0 '>
                        <Form.Label className=''>( Month )</Form.Label>
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className=''>( Day )</Form.Label>
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className=''>( Year )</Form.Label>
                      </Form.Group>
                    </div>
                    <div className='inline-group'>
                      <DropdownDate
                        classes={{
                          dateContainer: 'date-container',
                          yearContainer: 'year-container',
                          monthContainer: 'month-container',
                          dayContainer: 'day-container',
                          year: 'dropdown-year',
                          month: 'dropdown-month',
                          day: 'dropdown-day',
                        }}
                        order={[
                          DropdownComponent.day,
                          DropdownComponent.month,
                          DropdownComponent.year,
                        ]}
                        selectedDate={datemarriage.selectedDate}
                        options={{
                          yearReverse: true,
                          monthShort: true,
                          monthCaps: true,
                        }}
                        onDateChange={(date) => {
                          if (isEdit === false) return;

                          const dateconfig = Moment(date).format('YYYY-MM-DD');
                          const date_separete = dateconfig.split('-');
                          setFormData((prev) => {
                            return {
                              ...prev,
                              marriage_day: date_separete[2],
                              marriage_month: date_separete[1],
                              marriage_year: date_separete[0],
                            };
                          });
                          setDatemarriage((prev) => {
                            return { ...prev, selectedDate: Moment(date).format('YYYY-MM-DD') };
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col className='block-container'>
                    <h4 className='mb-4'>18b. PLACE</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label className=''>( City / Municipality )</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.marriage_place_city}
                          onChange={handleChange('marriage_place_city')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className=''>( Province )</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.marriage_place_province}
                          onChange={handleChange('marriage_place_province')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className=''>( Country )</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.marriage_place_country}
                          onChange={handleChange('marriage_place_country')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container medical-certificate'>
                    <h2>MEDICAL CERTIFICATE</h2>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container pb-0'>
                    <h4 className='mb-2'>19. CAUSES OF FETAL DEATH</h4>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>a. Main disease / condition of fetus</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.main_disease}
                        onChange={handleChange('main_disease')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>b. Other diseases / conditions of the fetus</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.other_diseases}
                        onChange={handleChange('other_diseases')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>c. Main maternal diseases / condition affecting fetus</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.main_maternal_diseases}
                        onChange={handleChange('main_maternal_diseases')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>d. Other maternal disease / condition affecting fetus</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.other_maternal_diseases}
                        onChange={handleChange('other_maternal_diseases')}
                      />
                    </Form.Group>

                    <Form.Group className='inline-label input-group p-0'>
                      <Form.Label>e. Other relevant circumtances</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.other_relevant_circumstances}
                        onChange={handleChange('other_relevant_circumstances')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='m-0'>
                  <Col className='block-container'>
                    <Form.Group className='inline-label input-group p-0'>
                      <Form.Label>
                        II. Other significant conditions contributing to death:
                      </Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.significant_conditions_contributing_to_death}
                        onChange={handleChange('significant_conditions_contributing_to_death')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <div className='inline-group justify-content-start'>
                      <h4 className='mb-2'>20. FETUS DIED:</h4>

                      <div className='selections inline'>
                        <Form.Check
                          type='radio'
                          name='fetus-died'
                          disabled={!isEdit}
                          label='1. Before Labor'
                          value='1. Before Labor'
                          checked={formData?.fetus_died === '1. Before Labor'}
                          onChange={handleChange('fetus_died')}
                        />
                        <Form.Check
                          type='radio'
                          name='fetus-died'
                          disabled={!isEdit}
                          label='2. During Labor / Delivery'
                          value='2. During Labor / Delivery'
                          checked={formData?.fetus_died === '2. During Labor / Delivery'}
                          onChange={handleChange('fetus_died')}
                        />
                        <Form.Check
                          type='radio'
                          name='fetus-died'
                          disabled={!isEdit}
                          label='3. Unknown'
                          value='3. Unknown'
                          checked={formData?.fetus_died === '3. Unknown'}
                          onChange={handleChange('fetus_died')}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>21. LENGHT OF PREGNANCY ( in completed weeks )</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.length_of_pregnancy_in_weeks}
                        onChange={handleChange('length_of_pregnancy_in_weeks')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={9} className='block-container right-border'>
                    <h4 className='mb-2'>{`22a. ATTENDANT { Physician, Nurse, Midwife, Hilot or Traditional Birth Attendant, None, others ( specify )}`}</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.attendant}
                        onChange={handleChange('attendant')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>22b. CERTIFICATE OF DEATH</h4>

                    <p className='certify'>
                      I hereby certify that the forgoing particulars are correct as near as same can
                      be ascertained and I further certify that I
                      <Form.Check
                        type='radio'
                        name='attended'
                        disabled={!isEdit}
                        className='form-check'
                        value={'have attended'}
                        checked={formData?.cert_of_death_have_attended === 'have attended'}
                        onChange={handleChange('cert_of_death_have_attended')}
                      ></Form.Check>
                      have attended /
                      <Form.Check
                        type='radio'
                        disabled={!isEdit}
                        name='not attended'
                        className='form-check'
                        value={'have not attended'}
                        checked={formData?.cert_of_death_have_attended === 'have not attended'}
                        onChange={handleChange('cert_of_death_have_attended')}
                      ></Form.Check>
                      have not attended the deceased and that death occurred at am/pm on the date of
                      death of specified above.
                    </p>
                  </Col>
                </Row>

                <Row className='m-0'>
                  <Col className='block-container'>
                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        value={formData?.cert_of_death_signature}
                        onChange={handleChange('cert_of_death_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_name_in_print}
                        onChange={handleChange('cert_of_death_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_title_or_position}
                        onChange={handleChange('cert_of_death_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_address}
                        onChange={handleChange('cert_of_death_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_date}
                        onChange={handleChange('cert_of_death_date')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container small-border'>
                    <h4 className='mb-5'>REVIEWED BY:</h4>

                    <div className='mb-5 signature m-auto'>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_reviewed_by}
                        onChange={handleChange('cert_of_death_reviewed_by')}
                      />
                      <p className='over-printed-name'>
                        Signature Over Printed Name of Health Officer
                      </p>
                    </div>

                    <div className='mb-5 signature m-auto'>
                      <FormControl
                        type='date'
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_reviewed_date}
                        onChange={handleChange('cert_of_death_reviewed_date')}
                      />
                      <p className='over-printed-name'>Date</p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>
                      23. CORPSE DISPOSAL
                      <span className=''>( Burial, Cremation, if others, specify )</span>
                    </h4>

                    <Form.Group className='input-group inline-label p-0'>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.corpse_disposal}
                        onChange={handleChange('corpse_disposal')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>24. BURIAL / CREMATION PERMIT</h4>

                    <Form.Group className='input-group mb-3 inline-label p-0'>
                      <Form.Label>Number</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.burial_cremation_permit_no}
                        onChange={handleChange('burial_cremation_permit_no')}
                      />
                    </Form.Group>

                    <Form.Group className='input-group inline-label p-0'>
                      <Form.Label>Date Issued</Form.Label>
                      <FormControl
                        type='date'
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.burial_cremation_permit_date_issued}
                        onChange={handleChange('burial_cremation_permit_date_issued')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container'>
                    <h4 className='mb-2 '>25. AUTOPSY</h4>
                    <Form.Group className='checkbox d-flex justify-content-center'>
                      <div className='selections'>
                        <Form.Check
                          required
                          label='Yes'
                          value='Yes'
                          type='radio'
                          name='isAutopsy'
                          disabled={!isEdit}
                          onChange={handleChange('isAutopsy')}
                          checked={formData?.isAutopsy === 'Yes'}
                        />
                        <Form.Check
                          required
                          label='No'
                          value='No'
                          type='radio'
                          name='isAutopsy'
                          disabled={!isEdit}
                          onChange={handleChange('isAutopsy')}
                          checked={formData?.isAutopsy === 'No'}
                        />
                      </div>
                    </Form.Group>
                    {/* <Form.Group className='input-group mb-3 inline-label p-0'>
                      <Form.Label>Number</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        onChange={handleChange('transfer_permit_no')}
                      />
                    </Form.Group>

                    <Form.Group className='input-group inline-label p-0'>
                      <Form.Label>Date Issued</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        onChange={handleChange('transfer_permit_date_issued')}
                      />
                    </Form.Group> */}
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>26. NAME AND ADDRESS OF CEMETERY OR CREMATORY</h4>

                    <Form.Group className='input-group inline-label p-0'>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.name_and_address_of_cemetery_or_crematory}
                        onChange={handleChange('name_and_address_of_cemetery_or_crematory')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>26. CERTIFICATION OF INFORMAT</h4>

                    <p className='certify'>
                      I hereby certify that all information supplied are true and correct to my own
                      knowledge and belief.
                    </p>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        value={formData?.cert_of_informat_signature}
                        onChange={handleChange('cert_of_informat_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_name_in_print}
                        onChange={handleChange('cert_of_informat_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_title_or_position}
                        onChange={handleChange('cert_of_informat_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_address}
                        onChange={handleChange('cert_of_informat_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_date}
                        onChange={handleChange('cert_of_informat_date')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='block-container'>
                    <h4 className='mb-2'>27. PREPARED BY</h4>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        value={formData?.prepared_by_signature}
                        onChange={handleChange('prepared_by_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.prepared_by_name_in_print}
                        onChange={handleChange('prepared_by_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.prepared_by_title_or_position}
                        onChange={handleChange('prepared_by_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.prepared_by_address}
                        onChange={handleChange('prepared_by_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.prepared_by_date}
                        onChange={handleChange('prepared_by_date')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>28. RECEIVED BY</h4>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        value={formData?.recieved_by_signature}
                        onChange={handleChange('recieved_by_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.recieved_by_name_in_print}
                        onChange={handleChange('recieved_by_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.recieved_by_title_or_position}
                        onChange={handleChange('recieved_by_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.recieved_by_address}
                        onChange={handleChange('recieved_by_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.recieved_by_date}
                        onChange={handleChange('recieved_by_date')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='block-container'>
                    <h4 className='mb-2'>29. REGISTERED BY THE CIVIL REGISTRAR</h4>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        value={formData?.civil_registrar_signature}
                        onChange={handleChange('civil_registrar_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_name_in_print}
                        onChange={handleChange('civil_registrar_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_title_or_position}
                        onChange={handleChange('civil_registrar_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_address}
                        onChange={handleChange('civil_registrar_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        disabled={!isEdit}
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_date}
                        onChange={handleChange('civil_registrar_date')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row remarks-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h2>REMARKS / ANNOTATIONS ( For LCRO / OCRG Use Only )</h2>
                  </Col>
                </Row>
              </div>

              <div className='black-row last-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h2>TO BE FILLED - UP AT THE OFFICE OF THE CIVIL REGISTRAR</h2>

                    <div className='boxes-cont'>
                      <div className='boxes'>
                        <p className='mb-1'>7</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>8</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>9</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>12</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>14</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>15</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>16</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>19a</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>19b</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          }
        />

        <ConditionalRender
          condition={isPageTwo}
          renderIf={
            <>
              <div className='mb-4 black-border-container'>
                <div className='black-row'>
                  <Row className='m-0'>
                    <Col className='block-container medical-certificate'>
                      <h2 className='m-0'>POSTMORTEM CERTIFICATION OF DEATH</h2>
                    </Col>
                  </Row>
                </div>

                <div className='black-row last-row'>
                  <Row className='m-0'>
                    <Col className='block-container'>
                      <h4 className='mb-2 text-center'>
                        I HEREBY VERTIFY that I have performed an autopsy upon the body of the
                        deceased and that cause of death was
                      </h4>

                      <Form.Group className='input-group p-0'>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.cause_of_death}
                          onChange={handleChange('cause_of_death')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                        <Form.Label>Signature</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.postmortem_signature}
                          onChange={handleChange('postmortem_signature')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Name in Print</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.postmortem_name_in_print}
                          onChange={handleChange('postmortem_name_in_print')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Date</Form.Label>
                        <FormControl
                          type='date'
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.postmortem_date}
                          onChange={handleChange('postmortem_date')}
                        />
                      </Form.Group>
                    </Col>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Title or Position</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.postmortem_title_or_position}
                          onChange={handleChange('postmortem_title_or_position')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Address</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.postmortem_address}
                          onChange={handleChange('postmortem_address')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className='mb-4 black-border-container'>
                <div className='black-row'>
                  <Row className='m-0'>
                    <Col className='block-container medical-certificate'>
                      <h2 className='m-0'>CERTIFICATION OF EMBALMER</h2>
                    </Col>
                  </Row>
                </div>

                <div className='black-row last-row'>
                  <Row className='m-0'>
                    <Col className='block-container'>
                      <h4 className='mb-2 text-center'>
                        I HEREBY VERTIFY that I have embalmed {textBox('name')} following all the
                        regulations prescribed by the Department of Health.
                      </h4>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                        <Form.Label>Signature</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.embalmer_signature}
                          onChange={handleChange('embalmer_signature')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Name in Print</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.embalmer_name_in_print}
                          onChange={handleChange('embalmer_name_in_print')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Date</Form.Label>
                        <FormControl
                          type='date'
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.embalmer_title_date}
                          onChange={handleChange('embalmer_title_date')}
                        />
                      </Form.Group>
                    </Col>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Title/Designation</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.embalmer_title_designation}
                          onChange={handleChange('embalmer_title_designation')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>License No.</Form.Label>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.embalmer_license_no}
                          onChange={handleChange('embalmer_license_no')}
                        />
                      </Form.Group>

                      <div className='inline-group'>
                        <Form.Group className='mb-3 input-group inline-label p-0'>
                          <Form.Label>Issued on</Form.Label>
                          <FormControl
                            disabled={!isEdit}
                            placeholder='Enter Here'
                            value={formData?.embalmer_license_issued_on}
                            onChange={handleChange('embalmer_license_issued_on')}
                          />
                        </Form.Group>

                        <Form.Group className='mb-3 input-group inline-label p-0'>
                          <Form.Label>at</Form.Label>
                          <FormControl
                            disabled={!isEdit}
                            placeholder='Enter Here'
                            value={formData?.embalmer_license_issued_at}
                            onChange={handleChange('embalmer_license_issued_at')}
                          />
                        </Form.Group>
                      </div>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Expiry Date</Form.Label>
                        <FormControl
                          type='date'
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.embalmer_license_expiry_date}
                          onChange={handleChange('embalmer_license_expiry_date')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className='mb-4 black-border-container'>
                <div className='black-row last-row'>
                  <Row className='m-0'>
                    <Col className='block-container affidavit'>
                      <h2>AFFIDAVIT FOR DELAYED REGISTRATION OF DEATH</h2>

                      <p>
                        I, {textBox('affidavit_name')}, of legal age
                        <div className='inline-group-check'>
                          <Form.Check
                            type='radio'
                            label='Single'
                            value='Single'
                            disabled={!isEdit}
                            className='form-check'
                            name='affidavit_status'
                            onChange={handleChange('affidavit_civil_status')}
                            checked={formData?.affidavit_civil_status === 'Single'}
                          />
                          <Form.Check
                            type='radio'
                            label='Married'
                            value='Married'
                            disabled={!isEdit}
                            className='form-check'
                            name='affidavit_status'
                            onChange={handleChange('affidavit_civil_status')}
                            checked={formData?.affidavit_civil_status === 'Married'}
                          />
                          <Form.Check
                            type='radio'
                            label='Divorced'
                            value='Divorced'
                            disabled={!isEdit}
                            className='form-check'
                            name='affidavit_status'
                            onChange={handleChange('affidavit_civil_status')}
                            checked={formData?.affidavit_civil_status === 'Divorced'}
                          />
                          <Form.Check
                            label='Widow'
                            value='Widow'
                            type='radio'
                            disabled={!isEdit}
                            className='form-check'
                            name='affidavit_status'
                            onChange={handleChange('affidavit_civil_status')}
                            checked={formData?.affidavit_civil_status === 'Widow'}
                          />
                          <Form.Check
                            type='radio'
                            label='Widower'
                            value='Widower'
                            disabled={!isEdit}
                            className='form-check'
                            name='affidavit_status'
                            onChange={handleChange('affidavit_civil_status')}
                            checked={formData?.affidavit_civil_status === 'Widower'}
                          />
                        </div>
                        , with residence and postal address {textBox('affidavit_address')}, after
                        being duly sworn in accordance with law, do hereby depose and say;
                      </p>

                      <p className='mb-3'>
                        1. That {textBox('affidavit_deceased_name')} died on{' '}
                        {textBox('affidavit_died_on')} in
                        {textBox('affidavit_died_in')} and was burried / cremated in
                        {textBox('affidavit_burried_or_cremated_in')} on{' '}
                        {textBox('affidavit_burried_or_cremated_on')}
                      </p>

                      <p className=''>2. That the deceased at the time of his / her death: </p>

                      <div className='mb-3 attended'>
                        <div className='inline-group'>
                          <Form.Check
                            type='radio'
                            disabled={!isEdit}
                            name='is_attended'
                            className='form-check'
                            label='was attended by'
                            checked={formData?.enable_attended === true}
                            onClick={() => {
                              setFormData((prev) => {
                                return {
                                  ...prev,
                                  enable_attended: true,
                                };
                              });
                            }}
                          />
                          <FormControl
                            placeholder='Enter Here'
                            value={formData?.affidavit_was_attended}
                            disabled={formData?.enable_attended !== true}
                            onChange={handleChange('affidavit_was_attended')}
                          />
                        </div>

                        <Form.Check
                          type='radio'
                          disabled={!isEdit}
                          name='is_attended'
                          className='form-check'
                          label='was not attended.'
                          checked={formData?.enable_attended === false}
                          onClick={() => {
                            setFormData((prev) => {
                              return {
                                ...prev,
                                enable_attended: false,
                              };
                            });
                          }}
                        />
                      </div>

                      <p className='mb-3'>
                        3. That the cause of death of the deceased was{' '}
                        {textBox('affidavit_cause_of_death')}
                      </p>

                      <p className='mb-3'>
                        4. That the reason for the delay in registering this death was due to
                        {textBox('affidavit_reason_for_late_registration_of_death')}
                      </p>

                      <p className='mb-3'>
                        5. That I am executing this affidavit to attest to the truthfulness of the
                        foregoing statements for all legal intents and purposes.
                      </p>

                      <p className='mb-3'>
                        In truth whereof, I have affixed my signature below this{' '}
                        {textBox('affidavit_day')} day of {textBox('affidavit_month')},{' '}
                        {textBox('affidavit_year')} at {textBox('affidavit_address_1')},
                        Philippines.
                      </p>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <div className='mb-5 signature'>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.affidavit_signature_over_printed_name_of_affiant}
                          onChange={handleChange(
                            'affidavit_signature_over_printed_name_of_affiant',
                          )}
                        />
                        <p className='over-printed-name'>
                          ( Signature Over Printed Name of Affiant )
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container affidavit'>
                      <p>
                        <b>SUBSCRIBED AND SWORN</b> to be me this{' '}
                        {textBox('affidavit_subscribed_and_sworn_day')} day of
                        {textBox('affidavit_subscribed_and_sworn_month')},{' '}
                        {textBox('affidavit_subscribed_and_sworn_year')} at{' '}
                        {textBox('addraffidavit_subscribed_and_sworn_addressess')}, Philippines,
                        affiant who exhibited to me his Community Tax Certificate{' '}
                        {textBox('affidavit_tax_certificate')}
                        issued on {textBox('affidavit_tax_certificate_issued_on')} at{' '}
                        {textBox('affidavit_tax_certificate_issued_at')}
                      </p>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <div className='mb-5 signature no-border '>
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_signature_of_administering_officer}
                          onChange={handleChange('affidavit_signature_of_administering_officer')}
                        />
                        <p className='over-printed-name'>
                          ( Signature of the Administering Officer )
                        </p>
                      </div>

                      <div className='mb-5 signature'>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.affidavit_name_in_print}
                          onChange={handleChange('affidavit_name_in_print')}
                        />
                        <p className='over-printed-name'>Name in Print</p>
                      </div>
                    </Col>
                    <Col className='block-container'>
                      <div className='mb-5 signature'>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.affidavit_position}
                          onChange={handleChange('affidavit_position')}
                        />
                        <p className='over-printed-name'>Position / Title / Designation</p>
                      </div>

                      <div className='mb-5 signature'>
                        <FormControl
                          disabled={!isEdit}
                          placeholder='Enter Here'
                          value={formData?.affidavit_address_2}
                          onChange={handleChange('affidavit_address_2')}
                        />
                        <p className='over-printed-name'>Address</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          }
        />

        <hr />

        <ConditionalRender
          condition={isPageOne}
          renderIf={
            <Row className=''>
              <Col className='form-buttons'>
                <Button
                  onClick={() => {
                    setPage(2);
                  }}
                  className='save'
                >
                  NEXT PAGE
                </Button>
              </Col>
            </Row>
          }
        />

        <ConditionalRender
          condition={isPageTwo}
          renderIf={
            <Row className=''>
              <Col className='form-buttons'>
                {/* Buttons to show for Add */}
                <ConditionalRender
                  condition={isAdding}
                  renderIf={
                    <>
                      <Button
                        type='submit'
                        className='save'
                        // disabled={trigger}
                      >
                        CREATE RECORD
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEdit(false);
                          setShowScreen(0);
                        }}
                        className='cancel'
                      >
                        CANCEL
                      </Button>
                    </>
                  }
                />

                {/* Buttons to show for Edit/View */}
                <ConditionalRender
                  condition={!isAdding}
                  renderIf={
                    <>
                      <ConditionalRender
                        condition={!isEditing}
                        renderIf={
                          <>
                            <Button
                              className='print'
                              onClick={() => {
                                setPage(1);
                              }}
                            >
                              Back
                            </Button>
                            <Button className='print'>PRINT CERTIFICATE</Button>
                            <Button
                              onClick={() => {
                                setIsEdit(true);
                              }}
                              className='edit'
                            >
                              EDIT CERTIFICATE
                            </Button>
                          </>
                        }
                      />

                      <ConditionalRender
                        condition={isEditing}
                        renderIf={
                          <>
                            <Button
                              className='print'
                              onClick={() => {
                                setPage(1);
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              onClick={() => {
                                saveChanges();
                              }}
                              className='save'
                            >
                              SAVE CHANGES
                            </Button>
                            <Button
                              onClick={() => {
                                setIsEdit(false);
                              }}
                              className='cancel'
                            >
                              CANCEL
                            </Button>
                          </>
                        }
                      />
                    </>
                  }
                />
              </Col>
            </Row>
          }
        />
      </Form>
      <PatientSelection
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
        patientSelection={patientSelection.filter((patient) => patient?.sex === 'female')}
        fields={formData}
        setFields={setFormData}
      />
    </>
  );
};

export default CertificateOfFetalDeath;
