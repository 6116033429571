/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';

import ConditionalRender from 'components/ConditionalRender';

// import assets below
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-right.svg';

// main component
const Profile = ({ showProfile, setShowProfile, setShowScreen }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`/medical_records/${showProfile.profileId}`)
      .then((response) => {
        setData(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <>
      <h1>Patient Profile</h1>
      <div className='view-profile'>
        <Row className='mb-4'>
          <Col>
            <Button
              className='back-button'
              onClick={() => {
                setShowScreen(0);
              }}
            >
              Back to Patient List
            </Button>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='profile-info'>
            <Form.Group className='mb-3 avatar-container'>
              <UserSVG />
            </Form.Group>

            <Form.Group className='profile-name'>
              <h4>
                <b>{data?.patient?.last_name}</b> ,
                {data?.patient?.prefix ? data?.patient?.prefix : ''} {data?.patient?.first_name}
              </h4>
              <h6>
                Patient ID: <span>{showProfile?.profileId}</span>
              </h6>
              <div className='info'>
                <p>
                  {data?.patient?.age} / {data?.patient?.sex}
                </p>{' '}
                &nbsp;
                <p>{moment(data?.patient?.date_of_birth).format('MMMM-DD-YYYY')}</p>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col className='card-container'>
            <Form.Group>
              <Form.Label>Active</Form.Label>

              <div
                onClick={() => {
                  setShowProfile({
                    visitNumber: data?.active?.visit_number,
                    patientId: data?.patient?.id,
                  });
                  setShowScreen(2);
                }}
                className='card'
              >
                <Row>
                  <Col sm={3}>
                    <p>
                      OPD Date <span>{data?.active?.date}</span>
                    </p>
                  </Col>
                  <Col sm={3}>
                    <p>
                      Department <span>OPD</span>
                    </p>
                  </Col>
                  <Col sm={3}>
                    <p>
                      Doctors:
                      {data?.active?.doctors.length > 0 ? (
                        data?.active?.doctors?.map((item, key) => {
                          return (
                            <span className='small' key={key}>
                              Dr. {item?.user?.full_name} {item?.user?.first_name}{' '}
                              {item?.user?.middle_name}
                            </span>
                          );
                        })
                      ) : (
                        <p>-</p>
                      )}
                    </p>
                  </Col>
                  <Col className='arrow'>
                    <ArrowSVG />
                  </Col>
                </Row>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='card-container'>
            <Form.Group>
              <Form.Label>Visit History</Form.Label>

              {data?.history?.length > 0 ? (
                data?.history?.map((item, key) => {
                  return (
                    <div
                      onClick={() => {
                        setShowProfile({
                          visitNumber: item.visit_number,
                        });
                        setShowScreen(2);
                      }}
                      className='card mb-2'
                      key={key}
                    >
                      <Row>
                        <Col sm={3}>
                          <p>
                            Emergency Date <span>{item?.date}</span>
                          </p>
                        </Col>
                        <Col sm={3}>
                          <p>
                            Length of Stay <span>{item?.los}</span>
                          </p>
                        </Col>
                        <Col sm={3}>
                          <p>
                            Doctors:
                            {item?.doctors?.length > 0 ? (
                              item?.doctors?.map((item, key) => {
                                return (
                                  <span className='small' key={key}>
                                    Dr. {item?.user?.full_name} {item?.user?.first_name}{' '}
                                    {item?.user?.middle_name}
                                  </span>
                                );
                              })
                            ) : (
                              <p>-</p>
                            )}
                          </p>
                        </Col>
                        <Col className='arrow'>
                          <ArrowSVG />
                        </Col>
                      </Row>
                    </div>
                  );
                })
              ) : (
                <p>No record found</p>
              )}
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};

Profile.defaultProps = {
  setShowScreen: () => {},
};

Profile.propTypes = {
  setShowScreen: PropTypes.func,
};

export default Profile;
