/* eslint-disable no-unneeded-ternary */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import uuidv4 from 'react-uuid';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import PatientSelection from './PatientSelection';
import ConditionalRender from 'components/ConditionalRender';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import PrintContent from './PrintComponents/EmergencyTreatmentRecord';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import {
  getMedicalRecordID,
  selectActivePatients,
  createEmergencyTreatmentRecord,
} from 'reducers/medicalRecordReducer';

// import assets below
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleMinusSVG } from 'assets/svg/circle-minus.svg';

// main component
const EmergencyTreatmentRecord = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();

  const printRef = useRef(null);

  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [isAdd, setIsAdd] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [validated, setValidated] = useState(false);
  const [defaultNurseNotes, setDefaultNurseNotes] = useState();
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [inputOtherFields, setInputOtherFields] = useState([
    { id: uuidv4(), date: '', time: '', bp: '', hr: '', rr: '', wt: '', cbg: '', signature: '' },
  ]);
  const [formData, setFormData] = useState({
    name_of_healthcare_facility: '',
    healthcare_facility_address: '',

    hrn: '',
    patient_lname: '',
    patient_mname: '',
    patient_fname: '',
    age: '',
    sex: '',
    date_of_birth: '',

    referred_by: '',
    mode_of_arrival: '',
    historian_is_patient: '',
    historian_is_parent: '',
    historian_is_family: '',
    historian_is_friend: '',
    historian_is_private_md: '',
    historian_is_guardian: '',
    historian_is_ems: '',
    historian_is_concerned_citizen: '',
    historian_is_others: '',

    vital_sign_hr_rr: '',
    vital_sign_bp: '',
    vital_sign__t: '',
    vital_sign__w: '',

    chief_complaint: '',
    history_of_present_illness: '',

    general_is_fever: '',
    general_is_chills: '',
    general_is_weakness: '',
    general_is_nausea: '',
    eye_is_redness: '',
    eye_is_itching: '',
    eye_is_blurred_vission: '',
    eye_is_diplopia: '',
    ent_is_congestion: '',
    ent_is_epistaxis: '',
    ent_is_sore_throat: '',
    ent_is_hoarseness: '',
    ent_is_ear_ache: '',
    ent_is_ear_discharge: '',
    cv_is_chest_pain: '',
    cv_is_palpitations: '',
    cv_is_orthropnea: '',
    cv_is_pedal_edema: '',
    cv_is_pnd: '',
    resp_is_dob: '',
    resp_is_cough: '',
    resp_is_sputum: '',
    resp_is_hemoptysis: '',
    resp_is_wheezing: '',
    gi_is_abdominal_pain: '',
    gi_is_vomitting: '',
    gi_is_constipation: '',
    gi_is_melena: '',
    gi_is_hematochezia: '',
    gi_is_hematemesis: '',
    gu_is_dysuria: '',
    gu_is_frequency: '',
    gu_is_nocturia: '',
    gu_is_vaginal_discharge: '',
    gu_is_vaginal_bleeding: '',
    neuro_is_headache: '',
    neuro_is_blackout: '',
    neuro_is_numbness: '',
    neuro_is_unsteady_galt: '',
    neuro_is_seizure: '',
    ms_is_neck_pain: '',
    ms_is_back_pain: '',
    ms_is_hip_pain: '',
    ms_is_shoulder_pain: '',
    ms_is_joint_pain: '',
    skin_is_rash: '',
    skin_is_swelling: '',
    skin_is_breast_discharge: '',
    skin_is_breast_masses: '',
    psych_is_anxious: '',
    psych_is_depression: '',
    psych_is_hallucination: '',
    psych_is_stress: '',
    psych_is_not_sleeping: '',
    physical_examination_others: '',
    past_medical_surgical_is_none: '',
    past_medical_surgical_is_ptb: '',
    past_medical_surgical_is_diabetes: '',
    past_medical_surgical_is_hyper_tension: '',
    past_medical_surgical_is_asthma: '',
    past_medical_surgical_is_cardiac: '',
    past_medical_surgical_is_icu_admission: '',
    past_medical_surgical_is_or: '',
    medications: '',
    family_is_none: '',
    family_is_ptb: '',
    family_is_diabetes: '',
    family_is_hyper_tension: '',
    family_is_asthma: '',
    family_is_cardiac: '',
    family_is_cancer: '',
    social_is_rash: '',
    social_is_swelling: '',
    social_is_breast_discharge: '',
    social_is_breast_masses: '',
    psych_smoker_ppd_x_yrs: '',
    psych_alcoholic_bev_drinker: '',
    psych_illicit_drug_use: '',
    psych_allergies: '',
    triage_officer_complaint: '',
    trage_date: '',
    trage_time: '',
    physical_examination: '',
    initial_assessment: '',
    diagnostics_is_cbg: '',
    diagnostics_is_cbc: '',
    diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p: '',
    diagnostics_is_uric_acid: '',
    diagnostics_is_alt_ast: '',
    diagnostics_is_protime_ptt: '',
    diagnostics_is_cpk_mb_cpk_mm_cpk_total: '',
    diagnostics_is_top_i_t: '',
    diagnostics_is_cbg_2: '',
    diagnostics_is_cbc_2: '',
    diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p_2: '',
    diagnostics_is_uric_acid_2: '',
    diagnostics_is_alt_ast_2: '',
    diagnostics_is_protime_ptt_2: '',
    diagnostics_is_cpk_mb_cpk_mm_cpk_total_2: '',
    diagnostics_is_top_i_t_2: '',
    diagnostics_islecg: '',
    diagnostics_is_xray_chest: '',
    diagnostics_is_xray_abdomen: '',
    diagnostics_is_xray_plain: '',
    diagnostics_is_xray_contast: '',
    diagnostics_is_xray_cranial: '',
    diagnostics_is_xray_cervical: '',
    diagnostics_is_xray_2d_eco: '',
    diagnostics_is_xray_hbt: '',
    diagnostics_is_xray_pelvic: '',
    diagnostics_is_xray_whole_abdomen: '',
    diagnostics_is_xray_transvaginal: '',
    therapeutics_oxygen: '',
    therapeutics_npo: '',
    therapeutics_ivf: '',
    therapeutics_lpm_via: '',
    therapeutics_diet: '',
    therapeutics_medications: '',
    therapeutics_diagnosis: '',
    therapeutics_time_given: '',
    therapeutics_signature: '',
    therapeutics_monitor: '',
    results_cbc: '',
    results_chemistry: '',
    results_ekg: '',
    results_radiographics: '',
    refferals_reffered_by: '',
    refferals_time: '',
    refferals_service: '',
    refferals_reason_for_referral: '',
    refferals_received_by: '',
    date: '',
    time: '',
    is_treated_and_discharge: '',
    is_absconded: '',
    is_admit: '',
    is_home_againts_medical_advice: '',
    is_er_death: '',
    is_dead_on_arrival: '',
    is_transfer_to_hospital: '',
    hospital_name: '',
    is_self_confuction: '',
    is_ambulance: '',
    is_private: '',
    discharge_diagnosis: '',
    medications_2: '',
    discharge_plans: '',
    special_instructions: '',
    follow_up_clinic: '',
    follow_up_date: '',
    follow_up_time: '',
    attending_physician_name: '',
    attending_physician_date: '',
    attending_physician_time: '',
    patientId: Math.random(),
    type: 'emergency_treatment',
    nurse_notes: inputOtherFields,
  });

  const isAdding = isAdd === true;
  const isEditing = isEdit === true;
  const isNotEditing = isEdit === false;

  // For Add
  const handlesubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createEmergencyTreatmentRecord({ ...formData, method: 'post' }));
      return;
    }
    toast.warning('Fill up the  fields');
    setValidated(true);
  };

  // For Edit
  const saveChanges = async () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });

    const res = await dispatch(
      createEmergencyTreatmentRecord({
        ...formData,
        method: 'put',
        type: 'emergency_treatment',
      }),
    );

    if (res.payload.status === 'Success') {
      setIsEdit(false);
      setDefaultData(formData);
      setDefaultNurseNotes(inputOtherFields);

      return;
    }

    toast.error('Record is not Created/Updated');
  };

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };

  const handleCheckBoxChange = (key) => (e) => {
    const data = formData[key];

    if (data === '') {
      setFormData({
        ...formData,
        [key]: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [key]: '',
      });
    }
  };

  /** nurse notes */

  const handleAddOtherFields = () => {
    setInputOtherFields([
      ...inputOtherFields,
      {
        id: uuidv4(),
        date: '',
        time: '',
        bp: '',
        hr: '',
        rr: '',
        wt: '',
        cbg: '',
        signature: '',
      },
    ]);
  };

  const handleChangeOtherInput = (uuid, event) => {
    const newInputOtherFields = inputOtherFields.map((i) => {
      if (uuid === i.id) {
        i[event.target.name] = event.target.value;
        setInputOtherFields((prev) => {
          return { ...prev, [event.target.name]: event.target.value };
        });
      }
      return i;
    });

    setInputOtherFields(newInputOtherFields);
  };

  const handleRemoveOtherFields = (uuid) => {
    const values = [...inputOtherFields];
    values.splice(
      values.findIndex((value) => value.id === uuid),
      1,
    );
    setInputOtherFields(values);
    // setFormData({ ...formData, nurse_notes: values });
  };

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    // console.log(inputOtherFields);
    setFormData({ ...formData, nurse_notes: inputOtherFields });
  }, [inputOtherFields]);

  useEffect(() => {
    dispatch(getPatients());

    if (viewForm?.patientID) {
      setTrigger({ ...trigger, medicalRecordID: true });
      const formID = viewForm?.data?.id;

      dispatch(getMedicalRecordID(formID));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
    }
  }, [isEdit]);

  useEffect(() => {
    const {
      isFailed,
      patientID,
      isSuccess,
      successData,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    } = logs;

    useCreateRecordResponse({
      trigger,
      isFailed,
      dispatch,
      isSuccess,
      patientID,
      setIsEdit,
      setTrigger,
      setShowScreen,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    });

    if (isFetchRecordSuccess && trigger.medicalRecordID) {
      const data = medicalRecordID.mr_emergency_treatment;

      // const data = medicalRecordID.find((dd) => type);
      console.log('medicalRecordID', medicalRecordID);

      if (data?.mr_emergency_treatment_nurse_notes.length > 0) {
        setFormData({ ...data, nurse_notes: data?.mr_emergency_treatment_nurse_notes });
        setInputOtherFields(data?.mr_emergency_treatment_nurse_notes);

        setDefaultData({ ...data, nurse_notes: data?.mr_emergency_treatment_nurse_notes });
        setDefaultNurseNotes(data?.mr_emergency_treatment_nurse_notes);
      } else {
        setFormData({ ...data, nurse_notes: [inputOtherFields] });
        setInputOtherFields([inputOtherFields]);

        setDefaultData({ ...data, nurse_notes: [inputOtherFields] });
        setDefaultNurseNotes([inputOtherFields]);
      }
    }
  }, [logs]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={formData} viewFormType={viewForm?.type} />
      </div>
      <Form noValidate validated={validated} onSubmit={handlesubmit}>
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.name_of_healthcare_facility}
                onChange={handleChange('name_of_healthcare_facility')}
              />
              <Form.Label>Name of Healthcare Facility</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.healthcare_facility_address}
                onChange={handleChange('healthcare_facility_address')}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='third-row'>
            <h2>I. TRIAGE RECORD</h2>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>PHYSICAL EXAMINATION</h3>

            <div className='box-cont'>
              <p className='small-header'>NAME ( Last, Given, Middle )</p>

              <div className='row-group mb-3'>
                <Form.Group className='input-group big'>
                  <Form.Label>Last Name</Form.Label>
                  <FormControl
                    required
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.patient_lname}
                    onClick={handleShowPatientSelection}
                    // onChange={handleChange('patient_lname')}
                  />
                </Form.Group>

                <Form.Group className='input-group big'>
                  <Form.Label>First Name</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.patient_fname}
                    onClick={handleShowPatientSelection}
                    // onChange={handleChange('patient_fname')}
                  />
                </Form.Group>

                <Form.Group className='input-group big'>
                  <Form.Label>Middle Name</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.patient_mname}
                    onClick={handleShowPatientSelection}
                    // onChange={handleChange('patient_mname')}
                  />
                </Form.Group>
              </div>

              <div className='row-group'>
                <Form.Group className='input-group small'>
                  <Form.Label>Age</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    value={formData?.age}
                    placeholder='Enter Here'
                    onChange={handleChange('age')}
                  />
                </Form.Group>

                <Form.Group className='input-group big'>
                  <Form.Label>Sex</Form.Label>

                  <Form.Group className='checkbox'>
                    <div className='selections'>
                      <Form.Check
                        name='sex'
                        type='radio'
                        label='Male'
                        value='Male'
                        disabled={!isEdit}
                        onChange={handleChange('sex')}
                        checked={formData?.sex === 'Male' || formData?.sex === 'male'}
                      />
                      <Form.Check
                        name='sex'
                        type='radio'
                        label='Female'
                        value='Female'
                        disabled={!isEdit}
                        onChange={handleChange('sex')}
                        checked={formData?.sex === 'Female' || formData?.sex === 'female'}
                      />
                    </div>
                  </Form.Group>
                </Form.Group>

                <Form.Group className='input-group big'>
                  <Form.Label>Date of Birth</Form.Label>
                  <FormControl
                    type='date'
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.date_of_birth}
                    onChange={handleChange('date_of_birth')}
                  />
                </Form.Group>
              </div>
            </div>

            <div className='box-cont d-flex'>
              <div className='info'>
                <h5>REFERRED BY</h5>

                <Form.Group className='checkbox input-group'>
                  <div className='selections'>
                    <Form.Check
                      type='radio'
                      label='Self'
                      value='Self'
                      disabled={!isEdit}
                      name='referred_by'
                      onChange={handleChange('referred_by')}
                      checked={formData?.referred_by === 'Self' ? true : false}
                    />
                    <Form.Check
                      type='radio'
                      label='OPD'
                      value='OPD'
                      name='referred_by'
                      disabled={!isEdit}
                      onChange={handleChange('referred_by')}
                      checked={formData?.referred_by === 'OPD' ? true : false}
                    />
                    <Form.Check
                      type='radio'
                      name='referred_by'
                      label='Private MD'
                      value='Private MD'
                      disabled={!isEdit}
                      onChange={handleChange('referred_by')}
                      checked={formData?.referred_by === 'Private MD' ? true : false}
                    />
                    <Form.Check
                      type='radio'
                      label='Hospital'
                      value='Hospital'
                      name='referred_by'
                      disabled={!isEdit}
                      onChange={handleChange('referred_by')}
                      checked={formData?.referred_by === 'Hospital' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info'>
                <h5>MODE OF ARRIVAL</h5>

                <Form.Group className='checkbox input-group '>
                  <div className='selections'>
                    <Form.Check
                      type='radio'
                      label='Ambulance'
                      value='Ambulance'
                      disabled={!isEdit}
                      name='mode_of_arrivals'
                      onChange={handleChange('mode_of_arrival')}
                      checked={formData?.mode_of_arrival === 'Ambulance' ? true : false}
                    />
                    <Form.Check
                      type='radio'
                      label='Walk - in'
                      value='Walk - in'
                      disabled={!isEdit}
                      name='mode_of_arrivals'
                      onChange={handleChange('mode_of_arrival')}
                      checked={formData?.mode_of_arrival === 'Walk - in' ? true : false}
                    />
                    <Form.Check
                      type='radio'
                      disabled={!isEdit}
                      name='mode_of_arrivals'
                      label='Private Vehicle'
                      value='Private Vehicle'
                      onChange={handleChange('mode_of_arrival')}
                      checked={formData?.mode_of_arrival === 'Private Vehicle' ? true : false}
                    />
                    <Form.Check
                      type='radio'
                      disabled={!isEdit}
                      label='Police Escort'
                      value='Police Escort'
                      name='mode_of_arrivals'
                      onChange={handleChange('mode_of_arrival')}
                      checked={formData?.mode_of_arrival === 'Police Escort' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info'>
                <h5>HISTORIAN</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Patient'
                      type='checkbox'
                      label='Patient'
                      value='Patient'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('historian_is_patient')}
                      checked={formData?.historian_is_patient === 'Patient' ? true : false}
                    />
                    <Form.Check
                      id='Parent'
                      label='Parent'
                      value='Parent'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('historian_is_parent')}
                      checked={formData?.historian_is_parent === 'Parent' ? true : false}
                    />
                    <Form.Check
                      id='Family'
                      label='Family'
                      value='Family'
                      type='checkbox'
                      disabled={!isEdit}
                      checked={formData?.historian_is_family === 'Family' ? true : false}
                      onChange={handleCheckBoxChange('historian_is_family')}
                    />
                    <Form.Check
                      id='Friend'
                      label='Friend'
                      value='Friend'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('historian_is_friend')}
                      checked={formData?.historian_is_friend === 'Friend' ? true : false}
                    />
                  </div>

                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      id='Private MD'
                      label='Private MD'
                      value='Private MD'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('historian_is_private_md')}
                      checked={formData?.historian_is_private_md === 'Private MD' ? true : false}
                    />
                    <Form.Check
                      id='Guardian'
                      type='checkbox'
                      label='Guardian'
                      value='Guardian'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('historian_is_guardian')}
                      checked={formData?.historian_is_guardian === 'Guardian' ? true : false}
                    />
                    <Form.Check
                      id='EMS'
                      label='EMS'
                      value='EMS'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('historian_is_ems')}
                      checked={formData?.historian_is_ems === 'EMS' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Concerned Citizen'
                      label='Concerned Citizen'
                      value='Concerned Citizen'
                      checked={
                        formData?.historian_is_concerned_citizen === 'Concerned Citizen'
                          ? true
                          : false
                      }
                      onChange={handleCheckBoxChange('historian_is_concerned_citizen')}
                    />
                  </div>

                  <div className='selections justify-content-start h-100'>
                    <Form.Check
                      id='Others'
                      label='Others'
                      value='Others'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('historian_is_others')}
                      checked={formData?.historian_is_others === 'Others' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className='box-cont'>
              <p className='small-header'>VITAL SIGNS</p>

              <div className='row-group mb-3' style={{ flexWrap: 'wrap' }}>
                <Form.Group className='input-group small'>
                  <Form.Label>HR / RR</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.vital_sign_hr_rr}
                    onChange={handleChange('vital_sign_hr_rr')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>BP</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.vital_sign_bp}
                    onChange={handleChange('vital_sign_bp')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>T</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.vital_sign__t}
                    onChange={handleChange('vital_sign__t')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>Weight</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.vital_sign__w}
                    onChange={handleChange('vital_sign__w')}
                  />
                </Form.Group>
              </div>
            </div>

            <div className='box-cont'>
              <div className='row-group'>
                <Form.Group className='input-group big'>
                  <Form.Label>CHIEF COMPLAINT</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.chief_complaint}
                    onChange={handleChange('chief_complaint')}
                  />
                </Form.Group>
              </div>
            </div>

            <div className='box-cont'>
              <div className='row-group'>
                <Form.Group className='input-group big'>
                  <Form.Label>HISTORY OF PRESENT ILLNESS</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.history_of_present_illness}
                    onChange={handleChange('history_of_present_illness')}
                  />
                </Form.Group>
              </div>
            </div>

            <h3>PHYSICAL EXAMINATION</h3>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <h5>GENERAL</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Fever'
                      label='Fever'
                      value='Fever'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('general_is_fever')}
                      checked={formData?.general_is_fever === 'Fever' ? true : false}
                    />
                    <Form.Check
                      id='Chills'
                      label='Chills'
                      value='Chills'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('general_is_chills')}
                      checked={formData?.general_is_chills === 'Chills' ? true : false}
                    />
                    <Form.Check
                      id='Weakness'
                      type='checkbox'
                      label='Weakness'
                      value='Weakness'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('general_is_weakness')}
                      checked={formData?.general_is_weakness === 'Weakness' ? true : false}
                    />
                    <Form.Check
                      id='Nausea'
                      label='Nausea'
                      value='Nausea'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('general_is_nausea')}
                      checked={formData?.general_is_nausea === 'Nausea' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>EYE</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Redness'
                      type='checkbox'
                      label='Redness'
                      value='Redness'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('eye_is_redness')}
                      checked={formData?.eye_is_redness === 'Redness' ? true : false}
                    />
                    <Form.Check
                      id='Itching'
                      type='checkbox'
                      label='Itching'
                      value='Itching'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('eye_is_itching')}
                      checked={formData?.eye_is_itching === 'Itching' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Blurred Vision'
                      label='Blurred Vision'
                      value='Blurred Vision'
                      onChange={handleCheckBoxChange('eye_is_blurred_vission')}
                      checked={formData?.eye_is_blurred_vission === 'Blurred Vision' ? true : false}
                    />
                    <Form.Check
                      id='Diplopia'
                      type='checkbox'
                      label='Diplopia'
                      value='Diplopia'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('eye_is_diplopia')}
                      checked={formData?.eye_is_diplopia === 'Diplopia' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>ENT</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      id='Congestion'
                      disabled={!isEdit}
                      label='Congestion'
                      value='Congestion'
                      onChange={handleCheckBoxChange('ent_is_congestion')}
                      checked={formData?.ent_is_congestion === 'Congestion' ? true : false}
                    />
                    <Form.Check
                      id='Epistaxis'
                      type='checkbox'
                      label='Epistaxis'
                      value='Epistaxis'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ent_is_epistaxis')}
                      checked={formData?.ent_is_epistaxis === 'Epistaxis' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Sore Throat'
                      label='Sore Throat'
                      value='Sore Throat'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ent_is_sore_throat')}
                      checked={formData?.ent_is_sore_throat === 'Sore Throat' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Hoarseness'
                      label='Hoarseness'
                      value='Hoarseness'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ent_is_hoarseness')}
                      checked={formData?.ent_is_hoarseness === 'Hoarseness' ? true : false}
                    />
                    <Form.Check
                      id='Ear Ache'
                      type='checkbox'
                      label='Ear Ache'
                      value='Ear Ache'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ent_is_ear_ache')}
                      checked={formData?.ent_is_ear_ache === 'Ear Ache' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Ear Discharge'
                      disabled={!isEdit}
                      label='Ear Discharge'
                      value='Ear Discharge'
                      onChange={handleCheckBoxChange('ent_is_ear_discharge')}
                      checked={formData?.ent_is_ear_discharge === 'Ear Discharge' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>CV</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      id='Chest Pain'
                      label='Chest Pain'
                      value='Chest Pain'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('cv_is_chest_pain')}
                      checked={formData?.cv_is_chest_pain === 'Chest Pain' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Palpitations'
                      disabled={!isEdit}
                      label='Palpitations'
                      value='Palpitations'
                      onChange={handleCheckBoxChange('cv_is_palpitations')}
                      checked={formData?.cv_is_palpitations === 'Palpitations' ? true : false}
                    />
                    <Form.Check
                      id='Orthopnea'
                      type='checkbox'
                      label='Orthopnea'
                      value='Orthopnea'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('cv_is_orthropnea')}
                      checked={formData?.cv_is_orthropnea === 'Orthopnea' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Pedal Edema'
                      disabled={!isEdit}
                      label='Pedal Edema'
                      value='Pedal Edema'
                      onChange={handleCheckBoxChange('cv_is_pedal_edema')}
                      checked={formData?.cv_is_pedal_edema === 'Pedal Edema' ? true : false}
                    />
                    <Form.Check
                      id='PND'
                      label='PND'
                      value='PND'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('cv_is_pnd')}
                      checked={formData?.cv_is_pnd === 'PND' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>RESP</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='DOB'
                      label='DOB'
                      value='DOB'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('resp_is_dob')}
                      checked={formData?.resp_is_dob === 'DOB' ? true : false}
                    />
                    <Form.Check
                      id='Cough'
                      label='Cough'
                      value='Cough'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('resp_is_cough')}
                      checked={formData?.resp_is_cough === 'Cough' ? true : false}
                    />
                    <Form.Check
                      id='Sputum'
                      label='Sputum'
                      value='Sputum'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('resp_is_sputum')}
                      checked={formData?.resp_is_sputum === 'Sputum' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Hemoptysis'
                      disabled={!isEdit}
                      label='Hemoptysis'
                      value='Hemoptysis'
                      onChange={handleCheckBoxChange('resp_is_hemoptysis')}
                      checked={formData?.resp_is_hemoptysis === 'Hemoptysis' ? true : false}
                    />
                    <Form.Check
                      id='Wheezing'
                      type='checkbox'
                      label='Wheezing'
                      value='Wheezing'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('resp_is_wheezing')}
                      checked={formData?.resp_is_wheezing === 'Wheezing' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>GI</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Abdominal pain'
                      label='Abdominal pain'
                      value='Abdominal pain'
                      onChange={handleCheckBoxChange('gi_is_abdominal_pain')}
                      checked={formData?.gi_is_abdominal_pain === 'Abdominal pain' ? true : false}
                    />
                    <Form.Check
                      id='Vomiting'
                      type='checkbox'
                      label='Vomiting'
                      value='Vomiting'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('gi_is_vomitting')}
                      checked={formData?.gi_is_vomitting === 'Vomiting' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Constipation'
                      disabled={!isEdit}
                      label='Constipation'
                      value='Constipation'
                      onChange={handleCheckBoxChange('gi_is_constipation')}
                      checked={formData?.gi_is_constipation === 'Constipation' ? true : false}
                    />
                    <Form.Check
                      id='Melena'
                      label='Melena'
                      value='Melena'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('gi_is_melena')}
                      checked={formData?.gi_is_melena === 'Melena' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Hematochezia'
                      disabled={!isEdit}
                      label='Hematochezia'
                      value='Hematochezia'
                      onChange={handleCheckBoxChange('gi_is_hematochezia')}
                      checked={formData?.gi_is_hematochezia === 'Hematochezia' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Hematemesis'
                      disabled={!isEdit}
                      label='Hematemesis'
                      value='Hematemesis'
                      onChange={handleCheckBoxChange('gi_is_hematemesis')}
                      checked={formData?.gi_is_hematemesis === 'Hematemesis' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <h5>GU</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Dysuria'
                      type='checkbox'
                      label='Dysuria'
                      value='Dysuria'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('gu_is_dysuria')}
                      checked={formData?.gu_is_dysuria === 'Dysuria' ? true : false}
                    />
                    <Form.Check
                      id='Frequency'
                      type='checkbox'
                      label='Frequency'
                      value='Frequency'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('gu_is_frequency')}
                      checked={formData?.gu_is_frequency === 'Frequency' ? true : false}
                    />
                    <Form.Check
                      id='Nocturia'
                      type='checkbox'
                      label='Nocturia'
                      value='Nocturia'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('gu_is_nocturia')}
                      checked={formData?.gu_is_nocturia === 'Nocturia' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Vaginal Discharge'
                      label='Vaginal Discharge'
                      value='Vaginal Discharge'
                      onChange={handleCheckBoxChange('gu_is_vaginal_discharge')}
                      checked={
                        formData?.gu_is_vaginal_discharge === 'Vaginal Discharge' ? true : false
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Vaginal Bleeding'
                      label='Vaginal Bleeding'
                      value='Vaginal Bleeding'
                      onChange={handleCheckBoxChange('gu_is_vaginal_bleeding')}
                      checked={
                        formData?.gu_is_vaginal_bleeding === 'Vaginal Bleeding' ? true : false
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>NEURO</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Headache'
                      type='checkbox'
                      label='Headache'
                      value='Headache'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('neuro_is_headache')}
                      checked={formData?.neuro_is_headache === 'Headache' ? true : false}
                    />
                    <Form.Check
                      id='Blackout'
                      type='checkbox'
                      label='Blackout'
                      value='Blackout'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('neuro_is_blackout')}
                      checked={formData?.neuro_is_blackout === 'Blackout' ? true : false}
                    />
                    <Form.Check
                      id='Numbness'
                      type='checkbox'
                      label='Numbness'
                      value='Numbness'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('neuro_is_numbness')}
                      checked={formData?.neuro_is_numbness === 'Numbness' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Unsteady Galt'
                      label='Unsteady Galt'
                      value='Unsteady Galt'
                      onChange={handleCheckBoxChange('neuro_is_unsteady_galt')}
                      checked={formData?.neuro_is_unsteady_galt === 'Unsteady Galt' ? true : false}
                    />
                    <Form.Check
                      id='Seizure'
                      type='checkbox'
                      label='Seizure'
                      value='Seizure'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('neuro_is_seizure')}
                      checked={formData?.neuro_is_seizure === 'Seizure' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>MS</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      id='Neck Pain'
                      label='Neck Pain'
                      value='Neck Pain'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ms_is_neck_pain')}
                      checked={formData?.ms_is_neck_pain === 'Neck Pain' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Back Pain'
                      label='Back Pain'
                      value='Back Pain'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ms_is_back_pain')}
                      checked={formData?.ms_is_back_pain === 'Back Pain' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Hip Pain'
                      label='Hip Pain'
                      value='Hip Pain'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ms_is_hip_pain')}
                      checked={formData?.ms_is_hip_pain === 'Hip Pain' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Shoulder Pain'
                      disabled={!isEdit}
                      label='Shoulder Pain'
                      value='Shoulder Pain'
                      onChange={handleCheckBoxChange('ms_is_shoulder_pain')}
                      checked={formData?.ms_is_shoulder_pain === 'Shoulder Pain' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Joint Pain'
                      label='Joint Pain'
                      value='Joint Pain'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('ms_is_joint_pain')}
                      checked={formData?.ms_is_joint_pain === 'Joint Pain' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>SKIN</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Rash'
                      label='Rash'
                      value='Rash'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('skin_is_rash')}
                      checked={formData?.skin_is_rash === 'Rash' ? true : false}
                    />
                    <Form.Check
                      id='Swelling'
                      type='checkbox'
                      label='Swelling'
                      value='Swelling'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('skin_is_swelling')}
                      checked={formData?.skin_is_swelling === 'Swelling' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Breast Discharge'
                      label='Breast Discharge'
                      value='Breast Discharge'
                      onChange={handleCheckBoxChange('skin_is_breast_discharge')}
                      checked={
                        formData?.skin_is_breast_discharge === 'Breast Discharge' ? true : false
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      id='Breast Masses'
                      disabled={!isEdit}
                      label='Breast Masses'
                      value='Breast Masses'
                      onChange={handleCheckBoxChange('skin_is_breast_masses')}
                      checked={formData?.skin_is_breast_masses === 'Breast Masses' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>PSYCH</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Anxious'
                      type='checkbox'
                      label='Anxious'
                      value='Anxious'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('psych_is_anxious')}
                      checked={formData?.psych_is_anxious === 'Anxious' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Depression'
                      label='Depression'
                      value='Depression'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('psych_is_depression')}
                      checked={formData?.psych_is_depression === 'Depression' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Hallucination'
                      label='Hallucination'
                      value='Hallucination'
                      onChange={handleCheckBoxChange('psych_is_hallucination')}
                      checked={formData?.psych_is_hallucination === 'Hallucination' ? true : false}
                    />
                    <Form.Check
                      id='Stress'
                      label='Stress'
                      value='Stress'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('psych_is_stress')}
                      checked={formData?.psych_is_stress === 'Stress' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Not Sleeping'
                      disabled={!isEdit}
                      label='Not Sleeping'
                      value='Not Sleeping'
                      onChange={handleCheckBoxChange('psych_is_not_sleeping')}
                      checked={formData?.psych_is_not_sleeping === 'Not Sleeping' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>OTHERS</h5>
                <div className='row-group'>
                  <Form.Group className='input-group big'>
                    <FormControl
                      rows={6}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.physical_examination_others}
                      onChange={handleChange('physical_examination_others')}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className='box-cont'>
              <div className='text-group'>
                <h4>ALL SYSTEMS REVIEWED</h4>

                <p>Negative</p>
                <p>All other systems negative</p>
                <p>Incomplete due to:</p>
                <p>Loss of Consciousness / Intubated / Exposure to toxic chemicals</p>
              </div>
            </div>

            <h3>REVIEW OF SYSTEMS</h3>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <h5>PAST MEDICAL / SURGICAL</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='None'
                      label='None'
                      value='None'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('past_medical_surgical_is_none')}
                      checked={formData?.past_medical_surgical_is_none === 'None' ? true : false}
                    />
                    <Form.Check
                      id='PTB'
                      label='PTB'
                      value='PTB'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('past_medical_surgical_is_ptb')}
                      checked={formData?.past_medical_surgical_is_ptb === 'PTB' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Diabetes'
                      label='Diabetes'
                      value='Diabetes'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('past_medical_surgical_is_diabetes')}
                      checked={
                        formData?.past_medical_surgical_is_diabetes === 'Diabetes' ? true : false
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      id='Hyper Tension'
                      disabled={!isEdit}
                      label='Hyper Tension'
                      value='Hyper Tension'
                      onChange={handleCheckBoxChange('past_medical_surgical_is_hyper_tension')}
                      checked={
                        formData?.past_medical_surgical_is_hyper_tension === 'Hyper Tension'
                          ? true
                          : false
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      id='Asthma'
                      label='Asthma'
                      value='Asthma'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('past_medical_surgical_is_asthma')}
                      checked={
                        formData?.past_medical_surgical_is_asthma === 'Asthma' ? true : false
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      id='Cardiac'
                      label='Cardiac'
                      value='Cardiac'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('past_medical_surgical_is_cardiac')}
                      checked={
                        formData?.past_medical_surgical_is_cardiac === 'Cardiac' ? true : false
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='ICU Admission'
                      label='ICU Admission'
                      value='ICU Admission'
                      onChange={handleCheckBoxChange('past_medical_surgical_is_icu_admission')}
                      checked={
                        formData?.past_medical_surgical_is_icu_admission === 'ICU Admission'
                          ? true
                          : false
                      }
                    />
                    <Form.Check
                      id='OR'
                      label='OR'
                      value='OR'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('past_medical_surgical_is_or')}
                      checked={formData?.past_medical_surgical_is_or === 'OR' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>MEDICATIONS</h5>
                <div className='row-group'>
                  <Form.Group className='input-group big'>
                    <FormControl
                      rows={12}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.medications}
                      onChange={handleChange('medications')}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className='info border-left'>
                <h5>FAMILY</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='None'
                      label='None'
                      value='None'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('family_is_none')}
                      checked={formData?.family_is_none === 'None' ? true : false}
                    />
                    <Form.Check
                      id='PTB'
                      label='PTB'
                      value='PTB'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('family_is_ptb')}
                      checked={formData?.family_is_ptb === 'PTB' ? true : false}
                    />
                    <Form.Check
                      id='Diabetes'
                      type='checkbox'
                      label='Diabetes'
                      value='Diabetes'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('family_is_diabetes')}
                      checked={formData?.family_is_diabetes === 'Diabetes' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Hyper Tension'
                      disabled={!isEdit}
                      label='Hyper Tension'
                      value='Hyper Tension'
                      onChange={handleCheckBoxChange('family_is_hyper_tension')}
                      checked={formData?.family_is_hyper_tension === 'Hyper Tension' ? true : false}
                    />
                    <Form.Check
                      id='Asthma'
                      label='Asthma'
                      value='Asthma'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('family_is_asthma')}
                      checked={formData?.family_is_asthma === 'Asthma' ? true : false}
                    />
                    <Form.Check
                      id='Cardiac'
                      type='checkbox'
                      label='Cardiac'
                      value='Cardiac'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('family_is_cardiac')}
                      checked={formData?.family_is_cardiac === 'Cardiac' ? true : false}
                    />
                    <Form.Check
                      id='Cancer'
                      label='Cancer'
                      value='Cancer'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('family_is_cancer')}
                      checked={formData?.family_is_cancer === 'Cancer' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <h5>SOCIAL</h5>

                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      id='Rash'
                      label='Rash'
                      value='Rash'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('social_is_rash')}
                      checked={formData?.social_is_rash === 'Rash' ? true : false}
                    />
                    <Form.Check
                      id='Swelling'
                      type='checkbox'
                      label='Swelling'
                      value='Swelling'
                      disabled={!isEdit}
                      onChange={handleCheckBoxChange('social_is_swelling')}
                      checked={formData?.social_is_swelling === 'Swelling' ? true : false}
                    />
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Breast Discharge'
                      label='Breast Discharge'
                      value='Breast Discharge'
                      onChange={handleCheckBoxChange('social_is_breast_discharge')}
                      checked={
                        formData?.social_is_breast_discharge === 'Breast Discharge' ? true : false
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      id='Breast Masses'
                      disabled={!isEdit}
                      label='Breast Masses'
                      value='Breast Masses'
                      onChange={handleCheckBoxChange('social_is_breast_masses')}
                      checked={formData?.social_is_breast_masses === 'Breast Masses' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              {/* <div className='info border-left'>
              <h5>PSYCH</h5>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check type='checkbox' id='Smoker ppd x yrs' label='Smoker ppd x yrs' />
                  <Form.Check
                    type='checkbox'
                    id='Alcocholic bev Drinker'
                    label='Alcocholic bev Drinker'
                  />
                  <Form.Check type='checkbox' id='Illicit drug use' label='Illicit drug use' />
                  <Form.Check type='checkbox' id='Allergies' label='Allergies' />
                </div>
              </Form.Group>
            </div> */}
              <div className='info border-left'>
                <h5></h5>
              </div>
              <div className='info '>
                <h5></h5>
              </div>
            </div>

            <div className='box-cont'>
              <div className='row-group'>
                <Form.Group className='input-group big'>
                  <Form.Label>TRIAGE OFFICER COMPLAINT</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.triage_officer_complaint}
                    onChange={handleChange('triage_officer_complaint')}
                  />
                </Form.Group>

                <Form.Group className='input-group big'>
                  <Form.Label>DATE</Form.Label>
                  <FormControl
                    type='date'
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.trage_date}
                    onChange={handleChange('trage_date')}
                  />
                </Form.Group>

                <Form.Group className='input-group big'>
                  <Form.Label>TIME</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.trage_time}
                    onChange={handleChange('trage_time')}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='third-row'>
            <h2>II. EMERGENCY ASSESSMENT AND DISPOSITION</h2>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>PHYSICAL EXAMINATION</h3>

            <div className='box-cont d-flex p-4'>
              <Form.Group className='input-group big'>
                <FormControl
                  rows={3}
                  as='textarea'
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.physical_examination}
                  onChange={handleChange('physical_examination')}
                />
              </Form.Group>
            </div>

            <div className='box-cont'>
              <div className='row-group'>
                <Form.Group className='input-group big'>
                  <Form.Label>INITIAL ASSESSMENT</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.initial_assessment}
                    onChange={handleChange('initial_assessment')}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>DIAGNOSTICS</h3>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      id='CBG'
                      label='CBG'
                      value='CBG'
                      onChange={handleCheckBoxChange('diagnostics_is_cbg')}
                      checked={formData?.diagnostics_is_cbg === 'CBG' ? true : false}
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='CBC'
                      label='CBC'
                      value='CBC'
                      onChange={handleCheckBoxChange('diagnostics_is_cbc')}
                      checked={formData?.diagnostics_is_cbc === 'CBC' ? true : false}
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='RBS BUN Crea Na K Cl Ca Mg P'
                      label='RBS BUN Crea Na K Cl Ca Mg P'
                      value='RBS BUN Crea Na K Cl Ca Mg P'
                      onChange={handleCheckBoxChange('diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p')}
                      checked={
                        formData?.diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p ===
                        'RBS BUN Crea Na K Cl Ca Mg P'
                          ? true
                          : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Uric Acid LDH Chol TG LDL HDL'
                      label='Uric Acid LDH Chol TG LDL HDL'
                      value='Uric Acid LDH Chol TG LDL HDL'
                      onChange={handleCheckBoxChange('diagnostics_is_uric_acid')}
                      checked={
                        formData?.diagnostics_is_uric_acid === 'Uric Acid LDH Chol TG LDL HDL'
                          ? true
                          : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='ALT AST alk phos'
                      label='ALT AST alk phos'
                      value='ALT AST alk phos'
                      onChange={handleCheckBoxChange('diagnostics_is_alt_ast')}
                      checked={
                        formData?.diagnostics_is_alt_ast === 'ALT AST alk phos' ? true : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Protime / PTT'
                      label='Protime / PTT'
                      value='Protime / PTT'
                      onChange={handleCheckBoxChange('diagnostics_is_protime_ptt')}
                      checked={
                        formData?.diagnostics_is_protime_ptt === 'Protime / PTT' ? true : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='CPK MB CPK MM CPK Total'
                      label='CPK MB CPK MM CPK Total'
                      value='CPK MB CPK MM CPK Total'
                      onChange={handleCheckBoxChange('diagnostics_is_cpk_mb_cpk_mm_cpk_total')}
                      checked={
                        formData?.diagnostics_is_cpk_mb_cpk_mm_cpk_total ===
                        'CPK MB CPK MM CPK Total'
                          ? true
                          : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Trop I T'
                      label='Trop I T'
                      value='Trop I T'
                      onChange={handleCheckBoxChange('diagnostics_is_top_i_t')}
                      checked={formData?.diagnostics_is_top_i_t === 'Trop I T' ? true : false}
                      disabled={!isEdit}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      id='CBG'
                      label='CBG'
                      value='CBG'
                      onChange={handleCheckBoxChange('diagnostics_is_cbg_2')}
                      checked={formData?.diagnostics_is_cbg_2 === 'CBG' ? true : false}
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='CBC'
                      label='CBC'
                      value='CBC'
                      onChange={handleCheckBoxChange('diagnostics_is_cbc_2')}
                      checked={formData?.diagnostics_is_cbc_2 === 'CBC' ? true : false}
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='RBS BUN Crea Na K Cl Ca Mg P'
                      label='RBS BUN Crea Na K Cl Ca Mg P'
                      value='RBS BUN Crea Na K Cl Ca Mg P'
                      onChange={handleCheckBoxChange(
                        'diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p_2',
                      )}
                      checked={
                        formData?.diagnostics_is_rbs_bun_crea_na_k_ci_ca_mg_p_2 ===
                        'RBS BUN Crea Na K Cl Ca Mg P'
                          ? true
                          : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Uric Acid LDH Chol TG LDL HDL'
                      label='Uric Acid LDH Chol TG LDL HDL'
                      value='Uric Acid LDH Chol TG LDL HDL'
                      onChange={handleCheckBoxChange('diagnostics_is_uric_acid_2')}
                      checked={
                        formData?.diagnostics_is_uric_acid_2 === 'Uric Acid LDH Chol TG LDL HDL'
                          ? true
                          : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='ALT AST alk phos'
                      label='ALT AST alk phos'
                      value='ALT AST alk phos'
                      onChange={handleCheckBoxChange('diagnostics_is_alt_ast_2')}
                      checked={
                        formData?.diagnostics_is_alt_ast_2 === 'ALT AST alk phos' ? true : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Protime / PTT'
                      label='Protime / PTT'
                      value='Protime / PTT'
                      onChange={handleCheckBoxChange('diagnostics_is_protime_ptt_2')}
                      checked={
                        formData?.diagnostics_is_protime_ptt_2 === 'Protime / PTT' ? true : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='CPK MB CPK MM CPK Total'
                      label='CPK MB CPK MM CPK Total'
                      value='CPK MB CPK MM CPK Total'
                      onChange={handleCheckBoxChange('diagnostics_is_cpk_mb_cpk_mm_cpk_total_2')}
                      checked={
                        formData?.diagnostics_is_cpk_mb_cpk_mm_cpk_total_2 ===
                        'CPK MB CPK MM CPK Total'
                          ? true
                          : false
                      }
                      disabled={!isEdit}
                    />
                    <Form.Check
                      type='checkbox'
                      id='Trop I T'
                      label='Trop I T'
                      value='Trop I T'
                      onChange={handleCheckBoxChange('diagnostics_is_top_i_t_2')}
                      checked={formData?.diagnostics_is_top_i_t_2 === 'Trop I T' ? true : false}
                      disabled={!isEdit}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <Form.Group className='input-group checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      id='12 - LECG'
                      label='12 - LECG'
                      value='12 - LECG'
                      onChange={handleCheckBoxChange('diagnostics_islecg')}
                      checked={formData?.diagnostics_islecg === '12 - LECG' ? true : false}
                      disabled={!isEdit}
                    />
                  </div>
                </Form.Group>

                <hr />

                <Form.Group className='input-group checkbox'>
                  <Form.Label>XRAY</Form.Label>

                  <Form.Check
                    type='checkbox'
                    id='Chest'
                    label='Chest'
                    value='Chest'
                    onChange={handleCheckBoxChange('diagnostics_is_xray_chest')}
                    checked={formData?.diagnostics_is_xray_chest === 'Chest' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    id='Abdomen'
                    label='Abdomen'
                    value='Abdomen'
                    onChange={handleCheckBoxChange('diagnostics_is_xray_abdomen')}
                    checked={formData?.diagnostics_is_xray_abdomen === 'Abdomen' ? true : false}
                    disabled={!isEdit}
                  />
                </Form.Group>

                <hr />

                <Form.Group className='input-group two-sides'>
                  <Form.Group className='checkbox'>
                    <div className='selections'>
                      <Form.Check
                        type='checkbox'
                        id='Plain'
                        label='Plain'
                        value='Plain'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_plain')}
                        checked={formData?.diagnostics_is_xray_plain === 'Plain' ? true : false}
                        disabled={!isEdit}
                      />
                      <Form.Check
                        type='checkbox'
                        id='Contrast'
                        label='Contrast'
                        value='Contrast'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_contrast')}
                        checked={
                          formData?.diagnostics_is_xray_contrast === 'Contrast' ? true : false
                        }
                        disabled={!isEdit}
                      />
                      <Form.Check
                        type='checkbox'
                        id='Cranial'
                        label='Cranial'
                        value='Cranial'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_cranial')}
                        checked={formData?.diagnostics_is_xray_cranial === 'Cranial' ? true : false}
                        disabled={!isEdit}
                      />
                      <Form.Check
                        type='checkbox'
                        id='Cervical'
                        label='Cervical'
                        value='Cervical'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_cervical')}
                        checked={
                          formData?.diagnostics_is_xray_cervical === 'Cervical' ? true : false
                        }
                        disabled={!isEdit}
                      />
                      {/* <Form.Check
                      type='checkbox'
                      id='Abdomen'
                      label='Abdomen'
                      value='Abdomen'
                      onChange={handleCheckBoxChange('diagnostics_is_xray_abdomen')}
                      checked={formData?.diagnostics_is_xray_abdomen === 'Abdomen' ? true : false}
                    /> */}
                    </div>
                  </Form.Group>

                  <hr />

                  <Form.Group className='checkbox'>
                    <div className='selections'>
                      <Form.Check
                        type='checkbox'
                        id='2D Echo'
                        label='2D Echo'
                        value='2D Echo'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_2d_eco')}
                        checked={formData?.diagnostics_is_xray_2d_eco === '2D Echo' ? true : false}
                        disabled={!isEdit}
                      />
                      <Form.Check
                        type='checkbox'
                        id='HBT'
                        label='HBT'
                        value='HBT'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_hbt')}
                        checked={formData?.diagnostics_is_xray_hbt === 'HBT' ? true : false}
                        disabled={!isEdit}
                      />
                      <Form.Check
                        type='checkbox'
                        id='Pelvic'
                        label='Pelvic'
                        value='Pelvic'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_pelvic')}
                        checked={formData?.diagnostics_is_xray_pelvic === 'Pelvic' ? true : false}
                        disabled={!isEdit}
                      />
                      <Form.Check
                        type='checkbox'
                        id='Whole Abdomen'
                        label='Whole Abdomen'
                        value='Whole Abdomen'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_whole_abdomen')}
                        checked={
                          formData?.diagnostics_is_xray_whole_abdomen === 'Whole Abdomen'
                            ? true
                            : false
                        }
                        disabled={!isEdit}
                      />
                      <Form.Check
                        type='checkbox'
                        id='Transvaginal'
                        label='Transvaginal'
                        value='Transvaginal'
                        onChange={handleCheckBoxChange('diagnostics_is_xray_transvaginal')}
                        checked={
                          formData?.diagnostics_is_xray_transvaginal === 'Transvaginal'
                            ? true
                            : false
                        }
                        disabled={!isEdit}
                      />
                    </div>
                  </Form.Group>
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>THERAPEUTICS</h3>

            <div className='box-cont'>
              <div className='row-group w-50 mb-3'>
                <Form.Group className='input-group medium'>
                  <Form.Label>Oxygen:</Form.Label>
                  <FormControl
                    className='w-100'
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    onChange={handleChange('therapeutics_oxygen')}
                    value={formData?.therapeutics_oxygen}
                  />
                </Form.Group>

                <Form.Group className='input-group medium'>
                  <Form.Label>LPM via</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_lpm_via}
                    onChange={handleChange('therapeutics_lpm_via')}
                  />
                </Form.Group>
              </div>

              <div className='row-group w-50 mb-3'>
                <Form.Group className='input-group medium'>
                  <Form.Label>NPO</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_npo}
                    onChange={handleChange('therapeutics_npo')}
                  />
                </Form.Group>

                <Form.Group className='input-group medium'>
                  <Form.Label>Diet</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_diet}
                    onChange={handleChange('therapeutics_diet')}
                  />
                </Form.Group>
              </div>

              <div className='row-group w-50 mb-3'>
                <Form.Group className='input-group medium'>
                  <Form.Label>IVF</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_ivf}
                    onChange={handleChange('therapeutics_ivf')}
                  />
                </Form.Group>
              </div>

              <div className='row-group mb-3'>
                <Form.Group className='input-group inline-header'>
                  <Form.Label>Medications</Form.Label>
                  <FormControl
                    rows={5}
                    as='textarea'
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_medications}
                    onChange={handleChange('therapeutics_medications')}
                  />
                </Form.Group>

                <Form.Group className='input-group inline-header'>
                  <Form.Label>Diagnosis</Form.Label>
                  <FormControl
                    rows={5}
                    as='textarea'
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_diagnosis}
                    onChange={handleChange('therapeutics_diagnosis')}
                  />
                </Form.Group>

                <Form.Group className='input-group inline-header-small'>
                  <Form.Label>Time Given</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_time_given}
                    onChange={handleChange('therapeutics_time_given')}
                  />
                </Form.Group>

                <Form.Group className='input-group inline-header-small'>
                  <Form.Label>Signature</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    className='no-border'
                    value={formData?.therapeutics_signature}
                    onChange={handleChange('therapeutics_signature')}
                  />
                </Form.Group>
              </div>

              <div className='row-group w-50 mb-3'>
                <Form.Group className='input-group big'>
                  <Form.Label>MONITOR</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.therapeutics_monitor}
                    onChange={handleChange('therapeutics_monitor')}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>RESULTS</h3>

            <div className='box-cont'>
              <div className='row-group mb-3' style={{ flexWrap: 'wrap' }}>
                <Form.Group className='input-group small'>
                  <Form.Label>CBC</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.results_cbc}
                    onChange={handleChange('results_cbc')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>CHEMISTRY</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.results_chemistry}
                    onChange={handleChange('results_chemistry')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>EKG</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.results_ekg}
                    onChange={handleChange('results_ekg')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>RADIOGRAPHICS</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.results_radiographics}
                    onChange={handleChange('results_radiographics')}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>REFERRALS</h3>

            <div className='box-cont'>
              <div className='row-group mb-3' style={{ flexWrap: 'wrap' }}>
                <Form.Group className='input-group small'>
                  <Form.Label>Referred by</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.refferals_reffered_by}
                    onChange={handleChange('refferals_reffered_by')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>Time</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.refferals_time}
                    onChange={handleChange('refferals_time')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>Service</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.refferals_service}
                    onChange={handleChange('refferals_service')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>Reason for Referral</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.refferals_reason_for_referral}
                    onChange={handleChange('refferals_reason_for_referral')}
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>Received by</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.refferals_received_by || ''}
                    onChange={handleChange('refferals_received_by')}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>NURSE&apos;S NOTES</h3>

            <div className='box-cont' style={{ borderBottom: 'none' }}>
              {formData.nurse_notes.map((inputOtherField, key, { length }) => {
                return (
                  <Row className='row-group mb-3' key={inputOtherField.id}>
                    <Col className='input-group mb-3 max-width'>
                      <div>
                        <p>Date/Time</p>
                        <Form.Control
                          type='text'
                          name='date'
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={inputOtherField.date}
                          onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                        />
                      </div>
                    </Col>
                    <Col className='input-group mb-3 max-width'>
                      <div>
                        <p>BP</p>
                        <Form.Control
                          name='bp'
                          type='text'
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={inputOtherField.bp}
                          onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                        />
                      </div>
                    </Col>
                    <Col className='input-group mb-3 max-width'>
                      <div>
                        <p>HR</p>
                        <Form.Control
                          name='hr'
                          type='text'
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={inputOtherField.hr}
                          onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                        />
                      </div>
                    </Col>
                    <Col className='input-group mb-3 max-width'>
                      <div>
                        <p>RR</p>
                        <Form.Control
                          name='rr'
                          type='text'
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={inputOtherField.rr}
                          onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                        />
                      </div>
                    </Col>
                    <Col className='input-group mb-3 max-width'>
                      <div>
                        <p>Time</p>
                        <Form.Control
                          type='text'
                          name='time'
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={inputOtherField.time}
                          onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                        />
                      </div>
                    </Col>
                    <Col className='input-group mb-3 max-width'>
                      <div>
                        <p>WT</p>
                        <Form.Control
                          name='wt'
                          type='text'
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={inputOtherField.wt}
                          onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                        />
                      </div>
                    </Col>
                    <Col className='input-group mb-3 max-width'>
                      <div>
                        <p>CBG</p>
                        <Form.Control
                          name='cbg'
                          type='text'
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={inputOtherField.cbg}
                          onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                        />
                      </div>
                    </Col>
                    <Col className='input-group mb-3 '>
                      <Row>
                        <Col>
                          <p>Signature</p>
                          <Form.Control
                            type='text'
                            name='signature'
                            disabled={!isEdit}
                            className='no-border'
                            placeholder='Enter Here'
                            value={inputOtherField.signature}
                            onChange={(event) => handleChangeOtherInput(inputOtherField.id, event)}
                          />
                        </Col>
                        <Col className='input-group'>
                          {key + 1 === length ? (
                            <Button variant='transparent' onClick={handleAddOtherFields}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30.795'
                                height='30.594'
                                viewBox='0 0 30.795 30.594'
                              >
                                <path
                                  id='circle-plus'
                                  d='M0,15.3a15.4,15.4,0,1,1,15.4,15.3A15.346,15.346,0,0,1,0,15.3ZM15.4,21.99a1.435,1.435,0,0,0,1.444-1.434V16.731H20.69a1.434,1.434,0,1,0,0-2.868H16.841V10.039a1.444,1.444,0,0,0-2.887,0v3.824H10.1a1.434,1.434,0,1,0,0,2.868h3.849v3.824A1.435,1.435,0,0,0,15.4,21.99Z'
                                  fill='#01b425'
                                />
                              </svg>
                            </Button>
                          ) : (
                            <Button variant='transparent' onClick={handleRemoveOtherFields}>
                              <CircleMinusSVG color='green' />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='blue-header-container p-0'>
            <h3>Patient Status</h3>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <div className='row-group mb-3'>
                  <Form.Group className='input-group small'>
                    <Form.Label>Date</Form.Label>
                    <FormControl
                      type='date'
                      disabled={!isEdit}
                      style={{ flex: '1' }}
                      value={formData?.date}
                      placeholder='Enter Here'
                      onChange={handleChange('date')}
                    />
                  </Form.Group>
                </div>

                <div className='row-group mb-3'>
                  <Form.Group className='input-group small'>
                    <Form.Label>Time</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      style={{ flex: '1' }}
                      value={formData?.time}
                      placeholder='Enter Here'
                      onChange={handleChange('time')}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className='info border-left'>
                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Treated and Discharge'
                      label='Treated and Discharge'
                      value='Treated and Discharge'
                      onChange={handleChange('is_treated_and_discharge')}
                      checked={
                        formData?.is_treated_and_discharge === 'Treated and Discharge'
                          ? true
                          : false
                      }
                    />
                    <Form.Check
                      id='Absconded'
                      type='checkbox'
                      label='Absconded'
                      value='Absconded'
                      disabled={!isEdit}
                      onChange={handleChange('is_absconded')}
                      checked={formData?.is_absconded === 'Absconded' ? true : false}
                    />
                    <Form.Check
                      id='Admit'
                      label='Admit'
                      value='Admit'
                      type='checkbox'
                      disabled={!isEdit}
                      onChange={handleChange('is_admit')}
                      checked={formData?.is_admit === 'Admit' ? true : false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Home Againts Medical Advice'
                      label='Home Againts Medical Advice'
                      value='Home Againts Medical Advice'
                      onChange={handleChange('is_home_againts_medical_advice')}
                      checked={
                        formData?.is_home_againts_medical_advice === 'Home Againts Medical Advice'
                          ? true
                          : false
                      }
                    />

                    <Form.Check
                      id='ER Death'
                      type='checkbox'
                      label='ER Death'
                      value='ER Death'
                      disabled={!isEdit}
                      onChange={handleChange('is_er_death')}
                      checked={formData?.is_er_death === 'ER Death' ? true : false}
                    />

                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Dead On Arrival ( DOA )'
                      label='Dead On Arrival ( DOA )'
                      value='Dead On Arrival ( DOA )'
                      onChange={handleChange('is_dead_on_arrival')}
                      checked={
                        formData?.is_dead_on_arrival === 'Dead On Arrival ( DOA )' ? true : false
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <div className='info border-left'>
                <Form.Group className='checkbox'>
                  <div className='selections'>
                    <div className='d-flex gap-10'>
                      <Form.Check
                        type='checkbox'
                        disabled={!isEdit}
                        id='Transfer to Hospital'
                        label='Transfer to Hospital'
                        value='Transfer to Hospital'
                        onChange={handleChange('is_transfer_to_hospital')}
                        checked={
                          formData?.is_transfer_to_hospital?.toLowerCase() ===
                          'transfer to hospital'
                            ? true
                            : false
                        }
                      />

                      <Form.Group className='input-group max-width'>
                        <FormControl
                          disabled={!isEdit}
                          className='max-width'
                          placeholder='Enter Here'
                          value={formData?.part_of_body_C}
                          onChange={handleChange('part_of_body_C')}
                        />
                      </Form.Group>
                    </div>
                    <Form.Check
                      type='checkbox'
                      disabled={!isEdit}
                      id='Self Conduction'
                      label='Self Conduction'
                      value='Self Conduction'
                      onChange={handleChange('is_self_confuction')}
                      checked={
                        formData?.is_self_confuction?.toLowerCase() === 'self conduction'
                          ? true
                          : false
                      }
                    />

                    <div className='d-flex gap-10'>
                      <Form.Check
                        id='Ambulance'
                        type='checkbox'
                        label='Ambulance'
                        value='Ambulance'
                        disabled={!isEdit}
                        onChange={handleChange('is_ambulance')}
                        checked={
                          formData?.is_ambulance?.toLowerCase() === 'ambulance' ? true : false
                        }
                      />
                      <Form.Check
                        id='Private'
                        type='checkbox'
                        label='Private'
                        value='Private'
                        disabled={!isEdit}
                        onChange={handleChange('is_private')}
                        checked={formData?.is_private?.toLowerCase() === 'private' ? true : false}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <div className='row-group mb-3'>
                  <Form.Group className='input-group inline-header align-items-start big'>
                    <Form.Label>Discharge Diagnosis</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.discharge_diagnosis}
                      onChange={handleChange('discharge_diagnosis')}
                    />
                  </Form.Group>
                </div>

                <div className='row-group mb-3'>
                  <Form.Group className='input-group inline-header align-items-start big'>
                    <Form.Label>Medications</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.medications_2}
                      onChange={handleChange('medications_2')}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className='info border-left'>
                <div className='row-group mb-3'>
                  <Form.Group className='input-group inline-header align-items-start big'>
                    <Form.Label>Discharge Plans</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.discharge_plans}
                      onChange={handleChange('discharge_plans')}
                    />
                  </Form.Group>
                </div>

                <div className='row-group mb-3'>
                  <Form.Group className='input-group inline-header align-items-start big'>
                    <Form.Label>Special Instructions</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.special_instructions}
                      onChange={handleChange('special_instructions')}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <h6>FOLLOW - UP</h6>
              </div>

              <div className='info border-left'>
                <h6>ATTENDING PHYSICIAN</h6>
              </div>
            </div>

            <div className='box-cont d-flex p-0'>
              <div className='info border-left'>
                <div className='row-group mb-3'>
                  <Form.Group className='input-group big'>
                    <Form.Label>Clinic</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.follow_up_clinic}
                      onChange={handleChange('follow_up_clinic')}
                    />
                  </Form.Group>
                </div>
                <div className='row-group mb-3'>
                  <Form.Group className='input-group small'>
                    <Form.Label>Date</Form.Label>
                    <FormControl
                      type='date'
                      disabled={!isEdit}
                      style={{ flex: '1' }}
                      placeholder='Enter Here'
                      value={formData?.follow_up_date}
                      onChange={handleChange('follow_up_date')}
                    />
                  </Form.Group>
                </div>
                <div className='row-group mb-3'>
                  <Form.Group className='input-group small'>
                    <Form.Label>Time</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      style={{ flex: '1' }}
                      placeholder='Enter Here'
                      value={formData?.follow_up_time}
                      onChange={handleChange('follow_up_time')}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className='info border-left'>
                <div className='row-group mb-3'>
                  <Form.Group className='input-group big'>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.attending_physician_name}
                      onChange={handleChange('attending_physician_name')}
                    />
                  </Form.Group>
                </div>

                <div className='row-group mb-3'>
                  <Form.Group className='input-group big'>
                    <Form.Label>Date</Form.Label>
                    <FormControl
                      type='date'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.attending_physician_date}
                      onChange={handleChange('attending_physician_date')}
                    />
                  </Form.Group>

                  <Form.Group className='input-group small'>
                    <Form.Label>Time</Form.Label>
                    <FormControl
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.attending_physician_time}
                      onChange={handleChange('attending_physician_time')}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>
                          PRINT CERTIFICATE
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          className='save'
                          disabled={trigger.trigger}
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setFormData(defaultData);
                            setInputOtherFields(defaultNurseNotes);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>

      <PatientSelection
        fields={formData}
        setFields={setFormData}
        patientSelection={patientSelection}
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
      />
    </>
  );
};

export default EmergencyTreatmentRecord;
